import { useMutation, useReactiveVar } from '@apollo/client';
import type { UseCreateBeneficiariesDocumentData } from '../Beneficiaries.types';
import {
  currentCompanyVar,
  globalBackdropVar,
  globalSnackbarVar,
  userVar,
} from '../../../cache.reactiveVars';
import { buildBeneficiaries } from '../Beneficiaries.helpers';
import { CREATE_BENEFICIARIES_DOCUMENT } from '../Beneficiaries.gql';

export function useCreateBeneficiariesDocument() {
  const company = useReactiveVar(currentCompanyVar);
  const user = useReactiveVar(userVar);
  const [createDocument] = useMutation(CREATE_BENEFICIARIES_DOCUMENT);

  const handleCreateBeneficiariesDocument = async (
    data: UseCreateBeneficiariesDocumentData,
  ) => {
    const { onSuccess, beneficiaries = [] } = data;

    if (!company?._id || !user?._id) {
      return globalSnackbarVar({
        show: true,
        message: 'Falta información de la empresa o del usuario',
        severity: 'error',
      });
    }
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Generando documento...',
      });

      const newBeneficiaries = buildBeneficiaries(beneficiaries, true);

      const response = await createDocument({
        variables: {
          input: {
            userId: user._id,
            companyId: company._id,
            beneficiaries: newBeneficiaries,
          },
        },
      });

      const data = response?.data?.createBeneficiariesDocument;

      if (data?.__typename === 'ErrorToCreateBeneficiariesDocument') {
        globalSnackbarVar({
          show: true,
          message: data.message,
          severity: 'error',
        });
        return;
      }

      if (onSuccess) {
        onSuccess(data);
      }
    } catch {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { handleCreateBeneficiariesDocument };
}
