// @ts-check
import { Box, Stack } from '@mui/material';
import { withErrorBoundary } from '@sentry/react';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../../../newComponents/Button';
import { Typography } from '../../../../../newComponents/Typography';
import { ControlledSwitch } from '../../../../../components/ControlledInputs/ControlledSwitch/ControlledSwitch';
import { ControlledUserInfoRequestedInput } from '../../../../../businessComponents/ControlledInputs';
import { DefaultErrorComponent } from '../../../../../components/ErrorBoundary/ErrorBoundary';
import { employeePermissions } from '../constants';
import { RequestBeneficiaresPermission } from './RequestBeneficiaresPermission';

/** @type {import('./EmployeePermissions.types').EmployeePermissionsFormValues} */
const defaultValues = {
  blockNewReceipts: false,
  blockBajaEmployees: false,
  completeBeneficiaries: false,
  requestBeneficiaries: false,
  requestBeneficiariesToEmployees: false,
  userInfoCompletion: false,
  userInfoRequested: [],
};

export const EmployeePermissions = withErrorBoundary(
  ({ companyData, updateEmployeePermissions }) => {
    const form = useForm({
      mode: 'onChange',
      shouldFocusError: true,
      defaultValues,
    });

    const {
      handleSubmit: configHandleSubmit,
      control: configControl,
      reset,
      formState: { isDirty },
      watch,
    } = form;

    const userInfoCompletion = watch('userInfoCompletion');
    const completeBeneficiaries = watch('completeBeneficiaries');

    useEffect(() => {
      let userInfoRequested =
        companyData.configs?.userInfoCompletion?.userInfoRequested || [];
      userInfoRequested = userInfoRequested.map(({ type, optional }) => ({
        type,
        optional,
      }));

      reset({
        blockBajaEmployees:
          companyData.configs?.blockBajaEmployees?.active || false,
        blockNewReceipts:
          companyData.configs?.blockNewReceipts?.active || false,
        completeBeneficiaries:
          companyData.configs?.completeBeneficiaries?.active || false,
        requestBeneficiaries:
          companyData.configs?.completeBeneficiaries?.required || false,
        requestBeneficiariesToEmployees:
          companyData.configs?.completeBeneficiaries?.requestToEmployees ||
          false,
        userInfoCompletion:
          companyData.configs?.userInfoCompletion?.active || false,
        userInfoRequested,
      });
    }, [companyData, reset]);

    return (
      <form onSubmit={configHandleSubmit(updateEmployeePermissions)}>
        <Stack gap={1}>
          {employeePermissions.map((config) => (
            <Stack key={config.name}>
              {config.section && (
                <Stack pb={2} pt={2}>
                  <Typography
                    variant="body2"
                    color="grey"
                    style={{ fontWeight: 600 }}
                  >
                    {config.section}
                  </Typography>
                </Stack>
              )}
              <ControlledSwitch
                control={configControl}
                name={config.name}
                description={config.description}
                renderText={(description) => (
                  <Typography variant="caption" color="grey">
                    {description}
                  </Typography>
                )}
                muiProps={{
                  switchProps: {},
                  labelProps: { label: config.label, labelPlacement: 'end' },
                }}
              />

              {config.name === 'completeBeneficiaries' &&
                completeBeneficiaries && (
                  <RequestBeneficiaresPermission form={form} />
                )}

              {config.name === 'userInfoCompletion' && userInfoCompletion && (
                <Stack mt={2.5}>
                  <ControlledUserInfoRequestedInput control={configControl} />
                </Stack>
              )}
            </Stack>
          ))}

          <Stack textAlign="right">
            <Box>
              <Button type="submit" variant="contained" disabled={!isDirty}>
                Actualizar
              </Button>
            </Box>
          </Stack>
        </Stack>
      </form>
    );
  },
  {
    fallback: <DefaultErrorComponent />,
  },
);
