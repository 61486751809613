import { gql } from '@apollo/client';

export const GET_BENEFICIARIES = gql`
  query getBeneficiaries($input: GetBeneficiariesInput) {
    getBeneficiaries(input: $input) {
      __typename
      ... on GetBeneficiariesResult {
        beneficiariesConfig {
          beneficiaries {
            _id
            names
            lastNameP
            lastNameM
            email
            phone
            relationship
            percentage
          }
          timestamps {
            createdAt
            updatedAt
          }
        }
        documentId
        isPending
        beneficiariesNotifications {
          sentBeneficiaryNotifications
          receivedBeneficiaryNotifications
        }
      }
    }
  }
`;

export const ADD_BENEFICIARIES = gql`
  mutation addBeneficiaries($input: AddBeneficiariesInput) {
    addBeneficiaries(input: $input) {
      __typename
      ... on ErrorToAddBeneficiaries {
        message
      }
      ... on AddBeneficiariesResult {
        success
        beneficiaries {
          _id
          names
          lastNameP
          lastNameM
          email
          phone
          relationship
          percentage
        }
      }
    }
  }
`;

export const UPDATE_BENEFICIARY = gql`
  mutation updateBeneficiary($input: UpdateBeneficiaryInput) {
    updateBeneficiary(input: $input) {
      __typename
      ... on ErrorToUpdateBeneficiary {
        message
      }
      ... on UpdateBeneficiaryResult {
        success
        beneficiary {
          _id
          names
          lastNameP
          lastNameM
          email
          phone
          relationship
          percentage
        }
      }
    }
  }
`;

export const REMOVE_BENEFICIARY = gql`
  mutation removeBeneficiary($input: RemoveBeneficiaryInput) {
    removeBeneficiary(input: $input) {
      __typename
      ... on ErrorToRemoveBeneficiary {
        message
      }
      ... on RemoveBeneficiaryResult {
        success
      }
    }
  }
`;

export const HANDLE_BENEFICIARIES = gql`
  mutation handleBeneficiaries($input: HandleBeneficiariesInput) {
    handleBeneficiaries(input: $input) {
      __typename
      ... on ErrorToHandleBeneficiaries {
        message
      }
      ... on HandleBeneficiariesResult {
        success
        beneficiaries {
          _id
          names
          lastNameP
          lastNameM
          email
          phone
          relationship
          percentage
        }
      }
    }
  }
`;

export const CREATE_BENEFICIARIES_DOCUMENT = gql`
  mutation createBeneficiariesDocument(
    $input: CreateBeneficiariesDocumentInput
  ) {
    createBeneficiariesDocument(input: $input) {
      __typename
      ... on ErrorToCreateBeneficiariesDocument {
        message
      }
      ... on CreateBeneficiariesDocumentResult {
        success
        message
        documentId
      }
    }
  }
`;

export const CONFIRM_BENEFICIARIES = gql`
  mutation confirmBeneficiaries($input: ConfirmBeneficiariesInput) {
    confirmBeneficiaries(input: $input) {
      __typename
      ... on ErrorToConfirmBeneficiaries {
        message
      }
      ... on ConfirmBeneficiariesResult {
        success
        beneficiaries {
          _id
          names
          lastNameP
          lastNameM
          email
          phone
          relationship
          percentage
        }
      }
    }
  }
`;

export const DELETE_BENEFICIARIES_DOCUMENT = gql`
  mutation deleteBeneficiariesDocument(
    $input: DeleteBeneficiariesDocumentInput
  ) {
    deleteBeneficiariesDocument(input: $input) {
      __typename
      ... on DeleteBeneficiariesDocumentResult {
        success
      }
    }
  }
`;

export const REQUEST_BENEFICIARIES_TO_EMPLOYEE = gql`
  mutation requestBeneficiariesToEmployee(
    $input: RequestBeneficiariesToEmployeeInput
  ) {
    requestBeneficiariesToEmployee(input: $input) {
      __typename
      ... on ErrorToRequestBeneficiariesToEmployee {
        message
      }
      ... on RequestBeneficiariesToEmployeeResult {
        success
        message
      }
    }
  }
`;
