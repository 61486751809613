import { Button } from '../../../newComponents';
import { useGetCompleteBeneficiariesConfig } from '../../Beneficiaries/Hooks/useGetCompleteBeneficiariesConfig';
import type { CompleteOnboardingBeneficiariesActionButtonProps } from './CompleteOnboardingBeneficiariesDialog.types';

export const CompleteOnboardingBeneficiariesActionButton = (
  props: CompleteOnboardingBeneficiariesActionButtonProps,
) => {
  const {
    handleSubmit,
    handleSkip,
    isValidPercentage,
    requestedByAdminIsPending,
    beneficiariesLength,
  } = props;
  const beneficiariesConfig = useGetCompleteBeneficiariesConfig();

  if (requestedByAdminIsPending) {
    return (
      <Button
        variant="contained"
        size="large"
        onClick={handleSubmit}
        disabled={!isValidPercentage}
      >
        Siguiente
      </Button>
    );
  }

  if (beneficiariesConfig.active && beneficiariesConfig.requestToEmployees) {
    return (
      <Button
        variant="contained"
        size="large"
        onClick={handleSubmit}
        disabled={!isValidPercentage}
      >
        Siguiente
      </Button>
    );
  }

  //Se puede saltar solo si está vacio o si ya tiene y no es forzoso para los empleados
  if (
    beneficiariesConfig.active &&
    !beneficiariesConfig.requestToEmployees &&
    beneficiariesLength === 0
  ) {
    return (
      <Button
        variant="contained"
        size="large"
        disabled={beneficiariesLength > 0 ? !isValidPercentage : false}
        onClick={handleSkip}
      >
        Siguiente
      </Button>
    );
  }

  return (
    <Button
      variant="contained"
      size="large"
      disabled={!isValidPercentage}
      onClick={handleSubmit}
    >
      Continuar
    </Button>
  );
};
