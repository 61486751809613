import { Grid, Stack, useTheme } from '@mui/material';
import { Avatar, Label, Tooltip, Typography } from '../../../newComponents';
import { RELATIONSHIP_MAP } from '../../ControlledInputs/ControlledRelationshipInput/ControlledRelationshipInput';
import type { BeneficiaryItemProps } from './BeneficiariesContainerList.types';

export const BeneficiaryItem = (props: BeneficiaryItemProps) => {
  const theme = useTheme();
  const { beneficiary, isAdminProfile, onClick } = props;

  const handleUpdateBeneficiary = () => {
    if (onClick) {
      onClick(beneficiary);
    }
  };

  if (isAdminProfile) {
    return (
      <Tooltip
        title='No puedes editar esta información. Haz click en "Ver información" para solicitar al empleado que la actualice'
        arrow
        placement="top"
      >
        <Grid item xs={12}>
          <span>
            <Stack
              padding="16px"
              width="100%"
              sx={{
                borderRadius: '16px',
                border: `1px solid ${theme.newPalette.grey.transparent24}`,
              }}
              spacing={1}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Avatar size="tiny" color="primary" />
                  <Typography variant="h5" color="text.secondary">
                    {beneficiary.names} {beneficiary.lastNameP}{' '}
                    {beneficiary.lastNameM || ''}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center">
                  <Label
                    color="secondary"
                    label={`${beneficiary.percentage}%`}
                    variant="soft"
                  />
                </Stack>
              </Stack>
              <Stack>
                <Typography variant="subtitle2" color="text.secondary">
                  {RELATIONSHIP_MAP[beneficiary.relationship]}
                </Typography>
              </Stack>
            </Stack>
          </span>
        </Grid>
      </Tooltip>
    );
  }

  return (
    <Grid
      item
      xs={12}
      md={isAdminProfile ? 12 : 6}
      onClick={handleUpdateBeneficiary}
    >
      <Stack
        padding="16px"
        width="100%"
        sx={{
          borderRadius: '16px',
          border: `1px solid ${theme.newPalette.grey.transparent24}`,
          cursor: 'pointer',
        }}
        spacing={1}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar size="tiny" color="primary" />
            <Typography variant="h5" color="text.secondary">
              {beneficiary.names} {beneficiary.lastNameP}{' '}
              {beneficiary.lastNameM || ''}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Label
              color="secondary"
              label={`${beneficiary.percentage}%`}
              variant="soft"
            />
          </Stack>
        </Stack>
        <Stack>
          <Typography variant="subtitle2" color="text.secondary">
            {RELATIONSHIP_MAP[beneficiary.relationship]}
          </Typography>
        </Stack>
      </Stack>
    </Grid>
  );
};
