import {
  Stack,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
  Button,
  ConfirmDialog,
  IconButton,
  Typography,
} from '../../../newComponents';
import { Icon } from '../../../components/Icons/Icons';
import { useForm } from 'react-hook-form';
import { defaultBeneficiariesFields } from '../Beneficiaries.constants';
import { useReactiveVar } from '@apollo/client';
import { Dialog } from '../../../components/Dialogs/Dialog';
import {
  resetUpdateBeneficiaryDialog,
  updateBeneficiaryDialogVar,
} from './UpdateBeneficiaryDialog.vars';
import { UpdateBeneficiaryDialogForm } from './UpdateBeneficiaryDialogForm';
import { UpdateBeneficiaryDialogProps } from './UpdateBeneficiaryDialog.types';
import { useEffect, useState } from 'react';
import { globalSnackbarVar } from '../../../cache.reactiveVars';
import { distributePercentages } from '../Beneficiaries.helpers';

export const UpdateBeneficiaryDialog = (
  props: UpdateBeneficiaryDialogProps,
) => {
  const { beneficiary, beneficiaries = [], setBeneficiaries } = props;
  const { open } = useReactiveVar(updateBeneficiaryDialogVar);
  const [openDialogToDelete, setOpenDialogToDelete] = useState(false);

  const form = useForm({
    mode: 'onChange',
    defaultValues: defaultBeneficiariesFields(),
  });

  const formIsValid = form.formState.isValid;
  const formIsDirty = form.formState.isDirty;

  const handleClose = () => {
    resetUpdateBeneficiaryDialog();
    form.reset();
  };

  const handleUpdate = () => {
    const values = form.getValues();
    const allBeneficiaries = beneficiaries.map((b) => {
      if (b._id === beneficiary?._id) {
        if (b.isRegistered) {
          return {
            ...values,
            isUpdated: true,
          };
        }
        return values;
      }
      return b;
    });

    setBeneficiaries(allBeneficiaries);
    globalSnackbarVar({
      show: true,
      message: 'Beneficiario actualizado con éxito',
      severity: 'success',
      duration: 5000,
    });
    handleClose();
  };

  const handleRemove = () => {
    if (!beneficiary) return;
    const allBeneficiaries = beneficiaries.filter(
      (b) => b._id !== beneficiary._id,
    );
    const newBeneficiaries = distributePercentages(allBeneficiaries);
    setBeneficiaries(newBeneficiaries);
    globalSnackbarVar({
      show: true,
      message: 'Beneficiario eliminado con éxito',
      severity: 'success',
      duration: 5000,
    });
    setOpenDialogToDelete(false);
    handleClose();
  };

  useEffect(() => {
    if (!beneficiary) return;
    form.reset(beneficiary);
  }, [beneficiary, form]);

  const allAreUnregistered = beneficiaries.every((b) => !b.isRegistered);

  return (
    <Dialog
      slideMode
      fullWidth
      open={open}
      maxWidth="xs"
      onClose={() => handleClose()}
      showCloseButton={false}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          py: 3,
        }}
        component="div"
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <Icon
            color="grey"
            icon="close_line"
            height="26px"
            onClick={() => handleClose()}
            style={{ cursor: 'pointer' }}
          />
          Actualizar Beneficiario
        </Stack>
        {beneficiaries.length > 1 || allAreUnregistered ? (
          <IconButton
            icon="delete_bin_line"
            color="error"
            size="small"
            onClick={() => setOpenDialogToDelete(true)}
          />
        ) : null}
      </DialogTitle>
      <DialogContent dividers>
        <UpdateBeneficiaryDialogForm form={form} />
      </DialogContent>
      <DialogActions sx={{ p: '24px' }}>
        <Button
          variant="contained"
          size="large"
          disabled={!formIsValid || !formIsDirty}
          onClick={form.handleSubmit(handleUpdate)}
        >
          Actualizar
        </Button>
      </DialogActions>
      <ConfirmDialog
        open={openDialogToDelete}
        onClose={() => setOpenDialogToDelete(false)}
        onConfirm={() => handleRemove()}
        onCancel={() => setOpenDialogToDelete(false)}
        icon="delete_bin_line"
        confirmLabel="Eliminar"
        cancelLabel="Cancelar"
        title="Eliminar beneficiario"
        severity="error"
      >
        <Stack width="100%">
          <Typography variant="body1" color="text.secondary">
            ¿Estas seguro que quieres eliminar a {beneficiary?.names} como
            beneficiario?
          </Typography>
        </Stack>
      </ConfirmDialog>
    </Dialog>
  );
};
