import { uuidv4 } from '../../utils/utils';
import type { BeneficiariesConfig } from './Beneficiaries.types';

export const defaultBeneficiariesFields = (): BeneficiariesConfig => {
  return {
    _id: uuidv4(),
    names: '',
    lastNameP: '',
    lastNameM: '',
    email: '',
    phone: '',
    relationship: '',
    percentage: '',
  };
};
