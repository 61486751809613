import { makeVar } from '@apollo/client';
import { AddBeneficiaryDialogVar } from './AddBeneficiaryDialog.types';

const addBeneficiaryDialogDefault: AddBeneficiaryDialogVar = {
  open: false,
  onFinish: () => {},
  onClose: () => {},
};

export const addBeneficiaryDialogVar = makeVar(addBeneficiaryDialogDefault);

export const setAddBeneficiaryDialogVar = (
  input: Partial<AddBeneficiaryDialogVar>,
) => {
  addBeneficiaryDialogVar({
    ...addBeneficiaryDialogVar(),
    ...input,
  });
};

export const resetAddBeneficiaryDialog = () => {
  addBeneficiaryDialogVar(addBeneficiaryDialogDefault);
};
