import { makeVar } from '@apollo/client';
import type { CompleteOnboardingBeneficiariesDialogVar } from './CompleteOnboardingBeneficiariesDialog.types';

const completeOnboardingBeneficiariesDialogDefault: CompleteOnboardingBeneficiariesDialogVar =
  {
    open: false,
    stepNumberLabel: '',
    showLogoutDialogAtClose: false,
    onFinish: () => {},
    onClose: () => {},
  };

export const completeOnboardingBeneficiariesDialogVar = makeVar(
  completeOnboardingBeneficiariesDialogDefault,
);

export const setCompleteOnboardingBeneficiariesDialogVar = (
  input: Partial<CompleteOnboardingBeneficiariesDialogVar>,
) => {
  completeOnboardingBeneficiariesDialogVar({
    ...completeOnboardingBeneficiariesDialogVar(),
    ...input,
  });
};

export const resetCompleteOnboardingBeneficiariesDialog = () => {
  completeOnboardingBeneficiariesDialogVar(
    completeOnboardingBeneficiariesDialogDefault,
  );
};
