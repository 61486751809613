import { Skeleton, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { BeneficiariesContainerList } from '../../../../../../businessComponents/Beneficiaries/BeneficiariesContainerList/BeneficiariesContainerList';
import { Typography } from '../../../../../../newComponents';
import { format } from 'date-fns';
import { EmployeeBeneficiariesHeader } from './EmployeeBeneficiariesHeader';
import type { EmployeeBeneficiariesProps } from './EmployeeBeneficiaries.types';
import type { BeneficiariesConfig } from '../../../../../../businessComponents/Beneficiaries/Beneficiaries.types';
import { useGetBeneficiaries } from '../../../../../../businessComponents/Beneficiaries/Hooks/useGetBeneficiaries';

export const EmployeeBeneficiaries = (props: EmployeeBeneficiariesProps) => {
  const { employee } = props;

  const {
    data,
    lastUpdated: timestamps,
    requestedByAdminIsPending,
    lastDocumentId,
    beneficiariesNotifications,
    loading,
  } = useGetBeneficiaries({
    userId: employee.employeeId,
    excludeLoading: true,
  });

  const [beneficiaries, setBeneficiaries] = useState<BeneficiariesConfig[]>([]);

  useEffect(() => {
    if (data?.length) {
      setBeneficiaries(data);
    } else {
      setBeneficiaries([]);
    }
  }, [data]);

  if (loading) {
    return (
      <Stack width="100%" height="auto">
        <Skeleton variant="rectangular" height={50} />
        <Skeleton variant="rectangular" height={200} sx={{ mt: 2 }} />
      </Stack>
    );
  }

  return (
    <Stack width="100%" height="auto">
      <EmployeeBeneficiariesHeader
        beneficiariesLength={beneficiaries.length}
        employee={employee}
        requestedByAdminIsPending={requestedByAdminIsPending}
        documentId={lastDocumentId}
        beneficiariesNotifications={beneficiariesNotifications}
      />
      <BeneficiariesContainerList
        beneficiaries={beneficiaries}
        setBeneficiaries={setBeneficiaries}
        isAdminProfile
      />
      {timestamps?.updatedAt && beneficiaries.length ? (
        <Stack mt={2} width="100%" direction="row" justifyContent="flex-end">
          <Typography variant="subtitle2" color="text.secondary">
            Última actualización:{' '}
            {format(new Date(timestamps.updatedAt), 'dd/MM/yyyy')}
          </Typography>
        </Stack>
      ) : null}
    </Stack>
  );
};
