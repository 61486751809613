// @ts-check

/** @param {string} text */
export const capitalize = (text) => {
  const fistLetter = text.charAt(0).toUpperCase();
  const rest = text.slice(1).toLowerCase();
  return `${fistLetter}${rest}`;
};

/** @param {string} text */
const capitalizeFirstLetter = (text) => {
  const fistLetter = text.charAt(0).toUpperCase();
  const rest = text.slice(1);
  return `${fistLetter}${rest}`;
};

/** @param {string} text */
export const capitalizeWords = (text) => {
  return text.split(' ').map(capitalize).join(' ');
};

/** @param {string} text */
export const trim = (text) => {
  return text.trim();
};

/** @param {string} text */
export const toLowerCase = (text) => {
  return text.toLowerCase();
};

/** @param {string} text */
export const toUpperCase = (text) => {
  return text.toUpperCase();
};

/** @param {string} text */
export const toMxUpperCase = (text) => {
  return text.toLocaleUpperCase('es-MX');
};

/** @param {string} text */
export const removeExtraSpaces = (text) => {
  return text.replace(/\s+/g, ' ').trim();
};

/** @param {string} text */
export const removeSpacesBetweenLetters = (text) => {
  return text.replace(/\s+/g, '');
};

/** @param {string} text */
export const cleanString = (text) => {
  return text.replace(/[^ a-zA-Z0-9áéíóúüñÑ/-]/g, '');
};

/** @param {string} text */
export const numberWithCommas = (text) => {
  const numberAsString = text.toString();
  if (numberAsString === '') return '0';

  const hasDecimal = numberAsString.includes('.');
  const numberCleaned = numberAsString.replace(/[^0-9.]/g, '');

  const parts = numberCleaned.split('.');
  let beforeDecimal = parts[0];
  const afterDecimal = parts[1] || '';

  beforeDecimal = Number(beforeDecimal).toLocaleString();

  const formattedNumber = `${beforeDecimal}${hasDecimal ? `.${afterDecimal}` : ''}`;
  return formattedNumber;
};

const justNumbers = (text) => text.replace(/[^0-9]/g, '');

const sanitizersObject = {
  capitalize,
  capitalizeFirstLetter,
  capitalizeWords,
  trim,
  toLowerCase,
  toUpperCase,
  toMxUpperCase,
  removeExtraSpaces,
  removeSpacesBetweenLetters,
  cleanString,
  numberWithCommas,
  justNumbers,
};

/**
 * @typedef {keyof sanitizersObject} AvailableSanitizers
 * @param {object} input
 * @param {string} input.text
 * @param {AvailableSanitizers[]} input.sanitizers
 */
export const applySanitizers = ({ text, sanitizers }) => {
  return sanitizers.reduce((acc, sanitizer) => {
    const sanitizedText = sanitizersObject[sanitizer](acc);
    return sanitizedText;
  }, text);
};
