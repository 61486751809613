import { gql } from '@apollo/client';

export const GET_USER_ONBOARDING_STEPS = gql`
  query GetUserOnboardingSteps {
    getUserOnboardingSteps {
      onboardingStatus {
        isVisible
        isCompleted
      }
      verifyIdentity {
        completed
      }
      createElectronicSignature {
        completed
        verificationToken
        verificationTokenRequired
      }
      signOnboardingDocuments {
        completed
        pendingDocuments {
          title
          constantType
        }
      }
      uploadRequestedDocuments {
        completed
        totalPendingDocuments
      }
      completeRequestedInformation {
        completed
        fields
      }
      completeBeneficiaries {
        completed
      }
    }
  }
`;

export const MARK_ONBOARDING_AS_COMPLETED = gql`
  mutation MarkOnboardingAsCompleted {
    markOnboardingAsCompleted {
      isVisible
      isCompleted
      completedAt
    }
  }
`;
