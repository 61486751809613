import { useMutation } from '@apollo/client';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../cache.reactiveVars';
import { DELETE_BENEFICIARIES_DOCUMENT } from '../Beneficiaries.gql';

interface UseDeleteBeneficiariesDocumentData {
  onSuccess?: () => void;
  employeeDocumentId: string;
}

export function useDeleteBeneficiariesDocument() {
  const [deleteBeneficiariesDocument] = useMutation(
    DELETE_BENEFICIARIES_DOCUMENT,
  );

  const handleDeleteBeneficiariesDocument = async (
    data: UseDeleteBeneficiariesDocumentData,
  ) => {
    const { employeeDocumentId } = data;

    if (!employeeDocumentId) return;

    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Obteniendo información...',
      });

      await deleteBeneficiariesDocument({
        variables: {
          input: {
            employeeDocumentId,
          },
        },
      });
    } catch {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { handleDeleteBeneficiariesDocument };
}
