import type {
  BeneficiariesConfig,
  BeneficiariesConfigWithoutId,
} from './Beneficiaries.types';

export const updateBeneficiary = (
  beneficiary: BeneficiariesConfig,
  includeId?: boolean,
) => {
  return {
    ...(includeId && { _id: beneficiary._id }),
    names: beneficiary.names,
    lastNameP: beneficiary.lastNameP,
    lastNameM: beneficiary.lastNameM,
    email: beneficiary.email,
    phone: beneficiary.phone,
    relationship: beneficiary.relationship,
    percentage: beneficiary.percentage,
  };
};

export const buildBeneficiaries = (
  beneficiaries: BeneficiariesConfig[],
  inclueId?: boolean,
): BeneficiariesConfigWithoutId[] => {
  if (!beneficiaries?.length) return [];

  const updatedBeneficiaries = beneficiaries.map((b) => {
    return updateBeneficiary(b, inclueId);
  });

  return updatedBeneficiaries;
};

export const mapRegisteredBeneficiaries = (
  beneficiaries: BeneficiariesConfig[],
) => {
  if (!beneficiaries?.length) return [];

  return beneficiaries.map((b) => ({
    ...b,
    isRegistered: true,
  }));
};

export const distributePercentages = (
  beneficiaries: BeneficiariesConfig[],
): BeneficiariesConfig[] => {
  if (!beneficiaries?.length) return [];

  const count = beneficiaries.length;

  const basePercentage = Math.floor(100 / count);
  let remainder = 100 - basePercentage * count;

  return beneficiaries.map((b, index) => {
    const newPercentage = basePercentage + (index < remainder ? 1 : 0);
    return {
      ...b,
      percentage: `${newPercentage}`,
    };
  });
};
