import { Stack } from '@mui/material';
import { Typography } from '../../../../newComponents/Typography';
import { Button } from '../../../../newComponents/Button';
import type { CompletePersonalDataFormProps } from '../CompletePersonalInformationDialog.types';
import { PERSONAL_INFO_TYPE } from '../CompletePersonalInformationDialog.constants';
import { useScreenSize } from '../../../../Hooks';
import {
  ControlledBirthdateInput,
  ControlledGenderInput,
  ControlledMaritalStatusInput,
  ControlledSocialSecurityNumberInput,
} from '../../../../businessComponents/ControlledInputs';

export const CompletePersonalDataForm = (
  props: CompletePersonalDataFormProps,
) => {
  const { form, onSubmit, userInfoRequestedByCompany } = props;
  const { isMobile, sm } = useScreenSize();
  const stackPadding = isMobile ? 2 : 3;
  const stackWidth = isMobile || sm ? '100%' : '480px';

  const dataRequired = userInfoRequestedByCompany.map((info) => info.type);
  const isFormValid = form.formState.isValid;

  return (
    <>
      <Stack p={stackPadding} width={stackWidth}>
        <Typography variant={isMobile ? 'h5' : 'h6'}>
          Completa tus datos personales:
        </Typography>
      </Stack>
      <Stack p={stackPadding} gap={3} width={stackWidth}>
        {(dataRequired.includes(PERSONAL_INFO_TYPE.BIRTHDATE) ||
          dataRequired.includes(PERSONAL_INFO_TYPE.GENDER)) && (
          <Stack flex={1} gap={2} direction={isMobile ? 'column' : 'row'}>
            {dataRequired.includes(PERSONAL_INFO_TYPE.BIRTHDATE) && (
              <Stack flex={1}>
                <ControlledBirthdateInput
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Campo requerido',
                    },
                  }}
                  textFieldProps={{
                    label: 'Fecha de nacimiento',
                    placeholder: '',
                  }}
                />
              </Stack>
            )}
            {dataRequired.includes(PERSONAL_INFO_TYPE.GENDER) && (
              <Stack flex={1}>
                <ControlledGenderInput
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Campo requerido',
                    },
                  }}
                  textFieldProps={{ label: 'Sexo' }}
                />
              </Stack>
            )}
          </Stack>
        )}
        {dataRequired.includes(PERSONAL_INFO_TYPE.MARITAL_STATUS) && (
          <Stack>
            <ControlledMaritalStatusInput
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
              }}
              textFieldProps={{ label: 'Estado civil' }}
            />
          </Stack>
        )}
        {dataRequired.includes(PERSONAL_INFO_TYPE.SOCIAL_SECURITY_NUMBER) && (
          <Stack>
            <ControlledSocialSecurityNumberInput
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
              }}
              textFieldProps={{ label: 'Número de seguro social' }}
            />
          </Stack>
        )}
      </Stack>
      <Stack p={stackPadding} width={stackWidth}>
        <Button
          variant="contained"
          size="large"
          fullWidth
          disabled={!isFormValid}
          onClick={form.handleSubmit(onSubmit)}
        >
          Continuar
        </Button>
      </Stack>
    </>
  );
};
