import { DialogActions, DialogContent, Stack } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { BeneficiariesHeaderContainer } from '../../../../businessComponents/Beneficiaries/BeneficiariesHeaderContainer/BeneficiariesHeaderContainer';
import { BeneficiariesContainerList } from '../../../../businessComponents/Beneficiaries/BeneficiariesContainerList/BeneficiariesContainerList';
import { Button } from '../../../../newComponents';
import { useReactiveVar } from '@apollo/client';
import { globalSnackbarVar, userVar } from '../../../../cache.reactiveVars';
import { BeneficiariesConfig } from '../../../../businessComponents/Beneficiaries/Beneficiaries.types';
import { useGetBeneficiaries } from '../../../../businessComponents/Beneficiaries/Hooks/useGetBeneficiaries';
import { useCreateBeneficiariesDocument } from '../../../../businessComponents/Beneficiaries/Hooks/useCreateBeneficiariesDocument';
import { useDeleteBeneficiariesDocument } from '../../../../businessComponents/Beneficiaries/Hooks/useDeleteBeneficiariesDocument';
import { openFileViewerDialogInSignableMode } from '../../../../businessComponents/Dialogs/FileViewerDialog/FileViewerDialog.vars';
import { useScreenSize } from '../../../../Hooks';
import { beneficiariesDirty } from '../ProfileDialog.helpers';
import { beneficiariesIsDirtyVar } from '../ProfileDialog.vars';

export const ProfileBeneficiariesContainer = () => {
  const { isMobile } = useScreenSize();
  const user = useReactiveVar(userVar);
  const [beneficiaries, setBeneficiaries] = useState<BeneficiariesConfig[]>([]);
  const { data, refetch } = useGetBeneficiaries({ userId: user?._id || '' });
  const { handleCreateBeneficiariesDocument } =
    useCreateBeneficiariesDocument();
  const { handleDeleteBeneficiariesDocument } =
    useDeleteBeneficiariesDocument();

  const isDirty = beneficiariesDirty({
    old: data,
    current: beneficiaries,
  });

  const handleSubmit = () => {
    if (!user?._id) return;
    handleCreateBeneficiariesDocument({
      beneficiaries: beneficiaries,
      onSuccess: (document: any) => {
        openFileViewerDialogInSignableMode({
          file: {
            id: document?.documentId,
            title: 'Beneficiarios.pdf',
            type: 'DOCUMENT',
          },
          onSuccess: () => {
            refetch();
          },
          onCloseConfirmDialog: () => {
            handleDeleteBeneficiariesDocument({
              employeeDocumentId: document?.documentId,
            });
          },
          onNoFilesToSign: () => {
            globalSnackbarVar({
              show: true,
              message: 'No hay documentos para firmar',
              severity: 'info',
            });
          },
        });
      },
    });
  };

  useEffect(() => {
    setBeneficiaries(data?.length ? data : []);
  }, [data]);

  useEffect(() => {
    if (isDirty) {
      beneficiariesIsDirtyVar(true);
    } else {
      beneficiariesIsDirtyVar(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beneficiaries, data]);

  const percentageSum = beneficiaries.reduce(
    (acc, beneficiary) => acc + Number(beneficiary.percentage),
    0,
  );
  const isValidPercentage = percentageSum === 100;

  return (
    <Fragment>
      <DialogContent
        dividers
        sx={{
          py: 3,
          ...(!isMobile && { pb: 10 }),
        }}
      >
        <Stack width="100%">
          <BeneficiariesHeaderContainer
            beneficiariesLength={beneficiaries.length}
            setBeneficiaries={setBeneficiaries}
          />
          <BeneficiariesContainerList
            beneficiaries={beneficiaries}
            setBeneficiaries={setBeneficiaries}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ padding: '24px' }}>
        {beneficiaries.length >= 1 && (
          <Button
            variant="contained"
            size="large"
            disabled={!isValidPercentage}
            onClick={handleSubmit}
            fullWidth={false}
          >
            Siguiente
          </Button>
        )}
      </DialogActions>
    </Fragment>
  );
};
