import { Box, Stack, tooltipClasses } from '@mui/material';
import React, { useRef, useState } from 'react';
import { Typographies } from '../../../../components/Typographies/Typography';
import { hexToRgbHelper } from '../../../../utils/utils';
import {
  categoryBoxContainer,
  categoryContainer,
  documentTypeContainer,
  subcategoryBoxContainer,
  subcategoryContainter,
} from './style';
import { styled } from '@mui/system';
import { Tooltip } from '../../../../newComponents';
import { currentCompanyVar } from '../../../../cache.reactiveVars';
import { TypographyWithRef } from '../../../../newComponents/Typography/Typography';
import { useCustomTheme } from '../../../../Hooks/useCustomTheme';
import { AiTag } from '../../../../newComponents/AiTag/AiTag';
import {
  BENEFICIARIES_CONSTANT_TYPE,
  BENEFICIARIES_SUBCATEGORY,
  CSF_SUBCATEGORY,
  NSS_SUBCATEGORY,
  PERSONAL_CATEGORY,
} from '../../../Settings/Preferences/EmployeeRecordTagsCatalog/EmployeeRecordTagsCatalog.constants';

const customMessage = ({ document, unchangeableType }) => {
  if (unchangeableType) {
    return 'No es posible modificar la categoría de este documento, ya que fue generado por Sora';
  }

  if (document.isAbsenceDocument) {
    return 'No es posible modificar la categoría de este documento, ya que fue generada por Sora.';
  }

  return '';
};

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '10px',
    backgroundColor: theme.newPalette.grey.grey700,
  },
}));

/**
 * @param {object} props
 * @param {object} props.params
 * @param {Function} [ props.handleOpen ]
 * @param {boolean} [ props.addStyle ]
 * @param {number} [ props.columnWidth ]
 * @param {object} [ props.companyData ]
 */

/**
 * @type {React.FC<{
 *   params: {
 *    row: {
 *     _id?: string;
 *     id?: string;
 *     type?: string;
 *     constantType?: string;
 *     category: string;
 *     subcategory: string;
 *     isAbsenceDocument?: boolean;
 *     documentAction?: string;
 *    };
 *   };
 *   handleOpen?: (params: { documentId: string; category: string; subcategory: string }) => void;
 *   addStyle?: boolean;
 *   companyData?: any;
 *   columnWidth?: number;
 *   hideIconAiTag?: boolean;
 *   }>}
 */
export const RenderEmployeeRecordType = ({
  params,
  handleOpen,
  addStyle = false,
  companyData,
  columnWidth = 230,
  hideIconAiTag,
}) => {
  const { row } = params;
  let documentId = null;
  const { _id, id, type } = row;
  documentId = _id || id;
  const theme = useCustomTheme();

  const unchangeableType = [
    'onboarding',
    'admin',
    'personal',
    'beneficiaries',
  ].some((item) => item === type);

  const blockCategory = [
    `${PERSONAL_CATEGORY}_${NSS_SUBCATEGORY}`,
    `${PERSONAL_CATEGORY}_${CSF_SUBCATEGORY}`,
    `${PERSONAL_CATEGORY}_${BENEFICIARIES_SUBCATEGORY}`,
  ];

  const unchangeableMessage = customMessage({
    document: row,
    unchangeableType,
  });

  const categoryName = row?.category || 'Otros';
  const subcategoryName = row?.subcategory || 'Sin etiqueta';

  const currentCompany = companyData ? companyData : currentCompanyVar();

  const employeeRecordTags = currentCompany?.configs?.employeeRecordsTags;

  const category = employeeRecordTags?.find(
    (item) => item.category?.toLowerCase() === categoryName?.toLowerCase(),
  );

  const subCategories = category?.subcategories || [];
  const subcategoryFound = subCategories?.find(
    (item) =>
      item?.subcategory?.toLowerCase() === subcategoryName?.toLowerCase(),
  );

  const key = subcategoryFound
    ? `${category?.category}_${subcategoryFound?.subcategory}`
    : `${category?.category}_${subcategoryName}`;

  const color =
    category?.color ||
    (categoryName === 'Sora'
      ? theme.newPalette.success.main
      : theme.newPalette.grey.grey400);
  const colorWithOpacity = hexToRgbHelper(color, 0.6);

  const categoryRef = useRef(null);
  const subcategoryRef = useRef(null);
  const [showCategoryTooltip, setShowCategoryTooltip] = useState(false);
  const [showSubcatTooltip, setShowSubcatTooltip] = useState(false);

  function isOverflown(element) {
    return element.scrollWidth > element.clientWidth;
  }

  const handleMouseEnter = () => {
    const isCategoryOverflow = isOverflown(categoryRef.current);
    const isSubcategoryRef = isOverflown(subcategoryRef.current);
    setShowCategoryTooltip(isCategoryOverflow);
    setShowSubcatTooltip(isSubcategoryRef);
  };

  const categoryWidth = columnWidth * 0.4 - 5;
  const subcategoryWidth = columnWidth * 0.6 - 5;

  const isBeneficiaries = row?.constantType === BENEFICIARIES_CONSTANT_TYPE;

  const execHandleOpen =
    !unchangeableType &&
    handleOpen &&
    !row.isAbsenceDocument &&
    !blockCategory.includes(key) &&
    !isBeneficiaries;

  return (
    <Stack width="100%" gap={1}>
      <CustomTooltip
        title={unchangeableMessage}
        placement="left-start"
        enterDelay={2000}
        disableInteractive
      >
        <Box
          onClick={() =>
            execHandleOpen &&
            handleOpen({
              documentId,
              category: categoryName,
              subcategory: subcategoryName,
            })
          }
          sx={{
            cursor: !unchangeableType && handleOpen && 'pointer',
            ...documentTypeContainer,
            ...(addStyle && {
              ...Typographies.BodySmall,
              cursor: 'default',
            }),
          }}
        >
          <Tooltip
            title={showCategoryTooltip ? categoryName : ''}
            placement="bottom"
            disableInteractive={false}
          >
            <Box
              sx={{
                ...categoryBoxContainer,
                maxWidth: categoryWidth,
                width: 'max-content',
                backgroundColor: colorWithOpacity,
              }}
            >
              <TypographyWithRef
                sx={categoryContainer}
                variant="caption"
                bold
                ref={categoryRef}
                onMouseEnter={handleMouseEnter}
                ellipsis
              >
                {categoryName}
              </TypographyWithRef>
            </Box>
          </Tooltip>
          <Tooltip
            title={showSubcatTooltip ? subcategoryName : ''}
            placement="bottom"
            disableInteractive={false}
          >
            <Box
              border={`2px solid ${colorWithOpacity}`}
              sx={{
                ...subcategoryBoxContainer,
                maxWidth: subcategoryWidth,
                width: 'max-content',
              }}
            >
              <TypographyWithRef
                sx={subcategoryContainter}
                variant="caption"
                bold
                ref={subcategoryRef}
                onMouseEnter={handleMouseEnter}
                ellipsis
              >
                {subcategoryName}
              </TypographyWithRef>
            </Box>
          </Tooltip>
        </Box>
      </CustomTooltip>
      {hideIconAiTag ? null : (
        <AiTag
          categoryId={category?._id}
          subcategoryId={subcategoryFound?._id}
          type={row.documentAction}
        />
      )}
    </Stack>
  );
};
