import { makeVar } from '@apollo/client';
import type { BeneficiariesPercentageDialogVar } from './BeneficiariesPercentageDialog.types';

const beneficiariesPercentageDialogDefault: BeneficiariesPercentageDialogVar = {
  open: false,
  onFinish: () => {},
  onClose: () => {},
};

export const beneficiariesPercentageDialogVar = makeVar(
  beneficiariesPercentageDialogDefault,
);

export const setBeneficiariesPercentageDialogVar = (
  input: Partial<BeneficiariesPercentageDialogVar>,
) => {
  beneficiariesPercentageDialogVar({
    ...beneficiariesPercentageDialogVar(),
    ...input,
  });
};

export const resetBeneficiariesPercentageDialog = () => {
  beneficiariesPercentageDialogVar(beneficiariesPercentageDialogDefault);
};
