import { useReactiveVar, useMutation } from '@apollo/client';
import { COMPLETE_PERSONAL_INFORMATION } from '../CompletePersonalInformationDialog.gql';
import type { HandleCompletePersonalDataProps } from '../CompletePersonalInformationDialog.types';
import {
  completePersonalInformationDialogVar,
  resetCompletePersonalInformationDialog,
} from '../CompletePersonalInformationDialog.vars';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';
import { SKIPPED_FIELDS } from '../CompletePersonalInformationDialog.constants';

const isEmptyAddressFn = (addressData) => {
  if (!addressData) return true;
  return (
    !addressData.city ||
    !addressData.state ||
    !addressData.street ||
    !addressData.zipCode
  );
};

export const useCompletePersonalInformation = () => {
  const [completePersonalInformation] = useMutation(
    COMPLETE_PERSONAL_INFORMATION,
  );
  const { onFinish } = useReactiveVar(completePersonalInformationDialogVar);

  const handleCompletePersonalData = async (
    data: HandleCompletePersonalDataProps,
  ) => {
    const { personalDataForm, addressForm, addSkippedFields } = data;
    const personalData = personalDataForm ? personalDataForm.getValues() : {};
    const addressData = addressForm ? addressForm.getValues() : null;

    const isEmptyAddress = isEmptyAddressFn(addressData);
    const skippedFields: string[] = [];
    if (addSkippedFields) {
      Object.keys(personalData).forEach((key) => {
        if (!personalData[key]) {
          skippedFields.push(SKIPPED_FIELDS[key]);
        }
      });

      if (addressData && isEmptyAddress) {
        skippedFields.push('ADDRESS');
      }
    }

    try {
      globalBackdropVar({ open: true, text: 'Agregando información...' });
      await completePersonalInformation({
        variables: {
          input: {
            ...personalData,
            address: isEmptyAddress ? null : addressData,
            skippedFields,
          },
        },
      });
      globalSnackbarVar({
        show: true,
        message: 'Información agregada correctamente',
        severity: 'success',
      });
      resetCompletePersonalInformationDialog();
      onFinish();
    } catch (error) {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al agregar la información',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return {
    handleCompletePersonalData,
  };
};
