import React from 'react';
import { TextInput } from '../../../../newComponents';

type HolidaySchemeSelectorProps = {
  fullWidth?: boolean;
};

export const HolidaySchemeSelector: React.FC<HolidaySchemeSelectorProps> = (
  props: HolidaySchemeSelectorProps,
) => {
  const { fullWidth } = props;
  return (
    <TextInput
      size={'medium'}
      name="holidays_scheme"
      placeholder="Esquema actual"
      label="Esquema actual"
      value="Vacaciones de ley"
      sx={{ width: fullWidth ? undefined : '300px' }}
      InputProps={{
        disabled: true,
      }}
      fullWidth={fullWidth}
    />
  );
};
