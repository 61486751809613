export const PERSONAL_INFO_TYPE = {
  BIRTHDATE: 'BIRTHDATE',
  GENDER: 'GENDER',
  MARITAL_STATUS: 'MARITAL_STATUS',
  ADDRESS: 'ADDRESS',
  SOCIAL_SECURITY_NUMBER: 'SOCIAL_SECURITY_NUMBER',
};

export const SKIPPED_FIELDS = {
  birthdate: PERSONAL_INFO_TYPE.BIRTHDATE,
  gender: PERSONAL_INFO_TYPE.GENDER,
  maritalStatus: PERSONAL_INFO_TYPE.MARITAL_STATUS,
  address: PERSONAL_INFO_TYPE.ADDRESS,
  socialSecurityNumber: PERSONAL_INFO_TYPE.SOCIAL_SECURITY_NUMBER,
};

export const DEFAULT_ADDRESS_DATA = {
  country: 'MEX',
  zipCode: '',
  state: '',
  city: '',
  colony: '',
  street: '',
  externalNumber: '',
  internalNumber: '',
};
