import { BeneficiariesDirtyType } from './ProfileDialog.types';

export const beneficiariesDirty = (data: BeneficiariesDirtyType) => {
  const { old = [], current = [] } = data;

  if (old.length !== current.length) return true;

  return old.some((beneficiary, index) => {
    const currentBeneficiary = current[index];

    return (
      beneficiary.names !== currentBeneficiary.names ||
      beneficiary.lastNameP !== currentBeneficiary.lastNameP ||
      beneficiary.lastNameM !== currentBeneficiary.lastNameM ||
      beneficiary.percentage !== currentBeneficiary.percentage ||
      beneficiary.relationship !== currentBeneficiary.relationship ||
      beneficiary.phone !== currentBeneficiary.phone
    );
  });
};
