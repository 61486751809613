import { useEffect, useMemo } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_INFO_REQUESTED_BY_COMPANY } from '../InfoRequestedByCompany.gql';
import {
  currentCompanyVar,
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../../cache.reactiveVars';
import type { InfoRequestedByCompany } from '../InfoRequestedByCompany.types';
import { modifyRequiredFields } from '../InfoRequestedByCompany.helpers';

export const useGetInfoRequestedByCompany = () => {
  const company = useReactiveVar(currentCompanyVar);
  const { data, loading, refetch } = useQuery(GET_INFO_REQUESTED_BY_COMPANY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: {
        companyId: company?._id,
      },
    },
    skip: !company?._id,
    onError: (err) => {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al obtener información',
        severity: 'error',
      });
    },
  });

  useEffect(() => {
    if (loading) {
      globalBackdropVar({ open: true, text: 'Cargando información...' });
    } else {
      globalBackdropVar({ open: false });
    }
  }, [loading]);

  const newInfoRequested = useMemo(() => {
    const infoRequestedByCompany =
      data?.getInfoRequestedByCompany?.requestedInfoToUsers ?? [];
    return modifyRequiredFields(infoRequestedByCompany);
  }, [data]);

  return {
    infoRequestedByCompany: newInfoRequested as InfoRequestedByCompany[],
    refetchInfoRequestedByCompany: refetch,
    loading: loading,
  };
};
