// @ts-check
import { Box, Grid, Stack } from '@mui/material';
import { Card, Content, SkeletonEmployee } from '../EmployeeInfo.styles';
import { EmployeePersonalDataForm } from './EmployeePersonalDataForm';
import { EmployeeContactDataForm } from './EmployeeContactDataForm';
import { EmployeeNominaDataForm } from './EmployeeNominaDataForm';
import { EmployeeGroupsDataForm } from './EmployeeGroupsDataForm';
import { useGetCompleteBeneficiariesConfig } from '../../../../../businessComponents/Beneficiaries/Hooks/useGetCompleteBeneficiariesConfig';
// import { MyBeneficiariesContainer } from '../../../../Landing/Profile/MyBeneficiaries/MyBeneficiariesContainer';
import { EmployeeBeneficiaries } from './EmployeeBeneficiaries/EmployeeBeneficiaries';
// Write JSDocs for this component
/**
 * @typedef EmployeeInfoGeneralDataProps
 * @property {boolean} loading
 * @property {import('../EmployeeInfo.types').EmployeeInfo} employeeData
 * @property {import("../../../../../types/permissions.types").UserPermissions} userPermissions
 * @property {import('../EmployeeInfo.types').PersonalDataForm} employeePersonalDataForm
 * @property {() => void} resetEmployeePersonalDataForm
 * @property {import('../EmployeeInfo.types').JobDataForm} employeeNominaDataForm
 * @property {() => void} resetEmployeeNominaDataForm
 * @property {import('../EmployeeInfo.types').ContactDataForm} employeeContactDataForm
 * @property {() => void} resetEmployeeContactDataForm
 */

/**
 * EmployeeInfoGeneralData component documented with JSDoc.
 *
 * @param {EmployeeInfoGeneralDataProps} props
 */
export const EmployeeInfoGeneralData = ({
  loading,
  employeeData,
  userPermissions,
  employeePersonalDataForm,
  resetEmployeePersonalDataForm,
  employeeContactDataForm,
  resetEmployeeContactDataForm,
  employeeNominaDataForm,
  resetEmployeeNominaDataForm,
}) => {
  const beneficiariesConfig = useGetCompleteBeneficiariesConfig();

  const isActive = beneficiariesConfig?.active;
  return (
    <>
      <Content>
        <Grid container spacing={3}>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} md={6}>
              <Box width="100%" mr={2}>
                {loading ? (
                  <SkeletonEmployee height={610} />
                ) : (
                  <Card variant="outlined">
                    <EmployeePersonalDataForm
                      employeeData={employeeData}
                      userPermissions={userPermissions}
                      employeePersonalDataForm={employeePersonalDataForm}
                      resetEmployeePersonalDataForm={
                        resetEmployeePersonalDataForm
                      }
                    />
                  </Card>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box width="100%" mr={2}>
                {loading ? (
                  <SkeletonEmployee height={610} />
                ) : (
                  <Card variant="outlined">
                    <EmployeeContactDataForm
                      employeeData={employeeData}
                      userPermissions={userPermissions}
                      employeeContactDataForm={employeeContactDataForm}
                      resetEmployeeContactDataForm={
                        resetEmployeeContactDataForm
                      }
                    />
                  </Card>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={isActive ? 6 : 12}>
              <Stack spacing={2}>
                {loading ? (
                  <SkeletonEmployee height={420} />
                ) : (
                  <Card variant="outlined">
                    <EmployeeNominaDataForm
                      employeeData={employeeData}
                      userPermissions={userPermissions}
                      employeeNominaDataForm={employeeNominaDataForm}
                      resetEmployeeNominaDataForm={resetEmployeeNominaDataForm}
                    />
                  </Card>
                )}
              </Stack>
            </Grid>
            {isActive && (
              <Grid
                item
                xs={12}
                md={6}
                sx={{ minHeight: 100, maxHeight: 600, overflow: 'auto' }}
              >
                <Stack spacing={2}>
                  {loading ? (
                    <SkeletonEmployee height={420} />
                  ) : (
                    <Card variant="outlined">
                      <EmployeeBeneficiaries
                        employee={{ employeeId: employeeData?.userId }}
                      />
                    </Card>
                  )}
                </Stack>
              </Grid>
            )}
            <Grid item xs={12}>
              <Stack spacing={2}>
                {loading ? (
                  <SkeletonEmployee height={150} />
                ) : (
                  <Card variant="outlined">
                    <EmployeeGroupsDataForm employeeData={employeeData} />
                  </Card>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Content>
    </>
  );
};
