import { useReactiveVar } from '@apollo/client';
import { currentCompanyVar } from '../../../cache.reactiveVars';
import type { CompanyBeneficiariesConfig } from '../Beneficiaries.types';

export const useGetCompleteBeneficiariesConfig =
  (): Partial<CompanyBeneficiariesConfig> => {
    const company = useReactiveVar(currentCompanyVar);

    const configBeneficiaries = company?.configs?.completeBeneficiaries;

    return {
      active: configBeneficiaries?.active ?? false,
      required: configBeneficiaries?.required ?? false,
      requestToEmployees: configBeneficiaries?.requestToEmployees ?? false,
    };
  };
