import { useReactiveVar } from '@apollo/client';
import { Button } from '../../../../../../newComponents';
import { fileViewerDialogVar } from '../../../../../../businessComponents/Dialogs/FileViewerDialog/FileViewerDialog.vars';
import { hasHoursPassedWithRemainingTime } from '../../../../../../utils/time.utils';

export interface RequestBeneficiariesButtonProps {
  handleRequestBeneficiariesFile: () => void;
}

export const RequestBeneficiariesButton = (
  props: RequestBeneficiariesButtonProps,
) => {
  const { handleRequestBeneficiariesFile } = props;
  const fileViewerDialog = useReactiveVar(fileViewerDialogVar);

  const { mode } = fileViewerDialog;
  const notifications =
    mode === 'BENEFICIARIES'
      ? fileViewerDialog?.beneficiariesNotifications
      : null;

  const hasHoursPassedResult = hasHoursPassedWithRemainingTime({
    givenDateString: notifications?.receivedBeneficiaryNotifications,
    hours: 24,
  });

  const canSendNotif = hasHoursPassedResult?.hasPassed;

  return (
    <Button
      variant="contained"
      color="primary"
      fullWidth
      onClick={handleRequestBeneficiariesFile}
      disabled={!canSendNotif}
      tooltipArrow
      tooltipText={
        !canSendNotif
          ? `Debes esperar 24 horas para enviar una nueva notificación, siguiente fecha disponible ${hasHoursPassedResult?.targetDateTime}`
          : ''
      }
      tooltipPlacement="top"
      tooltipStyle={{ width: '100%' }}
    >
      Solicitar actualización
    </Button>
  );
};
