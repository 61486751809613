import {
  Stack,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Slider,
} from '@mui/material';
import { Button, Label, Typography } from '../../../newComponents';
import { Icon } from '../../../components/Icons/Icons';
import { useReactiveVar } from '@apollo/client';
import { Dialog } from '../../../components/Dialogs/Dialog';
import {
  beneficiariesPercentageDialogVar,
  resetBeneficiariesPercentageDialog,
} from './BeneficiariesPercentageDialog.vars';
import { Controller, useForm } from 'react-hook-form';
import type {
  BeneficiariesPercentageDialogProps,
  BeneficiariesPercentageFormValues,
} from './BeneficiariesPercentageDialog.types';
import { useEffect, useState } from 'react';
import { distributePercentages } from '../Beneficiaries.helpers';
import { globalSnackbarVar } from '../../../cache.reactiveVars';

export const BeneficiariesPercentageDialog = (
  props: BeneficiariesPercentageDialogProps,
) => {
  const { beneficiaries = [], setBeneficiaries } = props;
  const { open } = useReactiveVar(beneficiariesPercentageDialogVar);
  const [lockedBeneficiaries, setLockedBeneficiaries] = useState<string[]>([]);
  const originalPercentages = beneficiaries.map((b) =>
    parseInt(b.percentage, 10),
  );
  const form = useForm<BeneficiariesPercentageFormValues>({
    defaultValues: {
      percentages: originalPercentages,
    },
  });

  const { control, watch, setValue } = form;

  const percentages = watch('percentages');

  const handleChange = (index: number, value: number) => {
    const totalBeneficiaries = percentages.length;

    if (totalBeneficiaries === 1) {
      setValue('percentages', [100]);
      return;
    }

    const updatedPercentages = [...percentages];
    const lockedIndexes: number[] = [];
    const unlockedIndexes: number[] = [];

    // Identificar beneficiarios bloqueados y desbloqueados
    beneficiaries.forEach((_, i) => {
      const beneficiary = beneficiaries[i];
      if (!beneficiary) return;
      const isLocked = lockedBeneficiaries.includes(beneficiary._id);
      isLocked ? lockedIndexes.push(i) : unlockedIndexes.push(i);
    });

    // Si solo hay un beneficiario desbloqueado, no hacer cambios
    if (unlockedIndexes?.length <= 1) return;

    // Calcular el total de porcentajes de beneficiarios bloqueados
    const lockedTotal = lockedIndexes.reduce(
      (sum, i) => sum + updatedPercentages[i],
      0,
    );

    // Calcular porcentaje restante para distribuir
    let remainingPercentage = 100 - lockedTotal;

    // Asegurar que el valor no supere el porcentaje restante
    const limitValue = Math.max(
      1,
      Math.min(value, remainingPercentage - (unlockedIndexes.length - 1)),
    );

    // Actualizar el porcentaje del beneficiario modificado
    updatedPercentages[index] = limitValue;

    // Recalcular porcentaje restante
    remainingPercentage -= limitValue;

    // Distribuir el porcentaje restante entre los beneficiarios desbloqueados
    const remainingUnlockedIndexes = unlockedIndexes.filter((i) => i !== index);
    const baseValue = Math.floor(
      remainingPercentage / remainingUnlockedIndexes.length,
    );
    let remainder = remainingPercentage % remainingUnlockedIndexes.length;

    remainingUnlockedIndexes.forEach((i, idx) => {
      updatedPercentages[i] = baseValue + (idx < remainder ? 1 : 0);
    });

    setValue('percentages', updatedPercentages);
  };
  const divideEqually = () => {
    const beneficiariesUpdated = distributePercentages(beneficiaries);
    const newPercentages = beneficiariesUpdated.map((b) =>
      Number(b.percentage),
    );

    setValue('percentages', newPercentages);
  };

  const handleClose = () => {
    resetBeneficiariesPercentageDialog();
    form.reset({ percentages: [] });
    setLockedBeneficiaries([]);
  };

  const lockItem = (id: string) => {
    if (!id) return;
    const beneficiary = lockedBeneficiaries.find(
      (blockedId) => blockedId === id,
    );

    if (beneficiary) {
      const updatedLocked = lockedBeneficiaries.filter(
        (blockedId) => blockedId !== id,
      );
      setLockedBeneficiaries(updatedLocked);
      return;
    }

    setLockedBeneficiaries((prev) => [...prev, id]);
  };

  const onSubmit = () => {
    const newPercentages = form.getValues().percentages ?? [];
    if (!newPercentages.length) return;
    const updatedBeneficiaries = beneficiaries.map((beneficiary, index) => ({
      ...beneficiary,
      percentage: newPercentages[index].toString(),
    }));

    const totalPercentage = newPercentages.reduce((sum, p) => sum + p, 0);
    if (totalPercentage !== 100) {
      globalSnackbarVar({
        show: true,
        message: 'Los porcentajes deben sumar 100%',
        severity: 'error',
        duration: 5000,
      });
      return;
    }

    setBeneficiaries(updatedBeneficiaries);
    globalSnackbarVar({
      show: true,
      message: 'Porcentajes actualizados con éxito',
      severity: 'success',
      duration: 5000,
    });
    handleClose();
  };

  useEffect(() => {
    if (beneficiaries?.length) {
      form.reset({ percentages: originalPercentages });
    } else {
      form.reset({ percentages: [] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beneficiaries]);

  const isDirty =
    JSON.stringify(originalPercentages) !== JSON.stringify(percentages);

  return (
    <Dialog
      slideMode
      fullWidth
      open={open}
      maxWidth="xs"
      onClose={() => handleClose()}
      showCloseButton={false}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          py: 3,
        }}
        component="div"
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <Icon
            color="grey"
            icon="close_line"
            height="26px"
            onClick={() => handleClose()}
            style={{ cursor: 'pointer' }}
          />
          Ajustar porcentajes
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <Stack spacing={2}>
          <Typography variant="h6">
            ¿Qué porcentaje recibirá cada beneficiario?
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Los porcentajes deben sumar el 100%
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              size="small"
              variant="outlined"
              onClick={divideEqually}
              color="secondary"
              startIcon={<Icon icon="percent_line" />}
            >
              Dividir por igual
            </Button>
          </Box>

          <Stack mt={2} spacing={3}>
            {beneficiaries.map((beneficiary, index) => {
              const isLocked = lockedBeneficiaries.includes(beneficiary._id);
              return (
                <Box key={beneficiary._id || index}>
                  <Stack
                    width="100%"
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={1}
                  >
                    <Typography>
                      {beneficiary.names} {beneficiary.lastNameP}{' '}
                      {beneficiary.lastNameM}
                    </Typography>
                    {isLocked ? (
                      <Icon
                        icon="lock_line"
                        color="text.disabled"
                        onClick={() => lockItem(beneficiary._id)}
                        style={{ cursor: 'pointer' }}
                      />
                    ) : (
                      <Icon
                        icon="lock_unlock_line"
                        onClick={() => lockItem(beneficiary._id)}
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                  </Stack>
                  <Label
                    label={`${percentages[index]}%`}
                    variant="soft"
                    color="secondary"
                    sx={{ width: 'fit-content' }}
                  />
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Box sx={{ width: '100%' }}>
                      <Controller
                        name={`percentages.${index}` as const}
                        control={control}
                        render={({ field }) => (
                          <Slider
                            {...field}
                            value={percentages[index] || 0}
                            onChange={(_, value) =>
                              handleChange(index, value as number)
                            }
                            min={1}
                            max={100}
                            disabled={isLocked}
                            valueLabelDisplay="auto"
                          />
                        )}
                      />
                    </Box>
                  </Stack>
                </Box>
              );
            })}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          variant="contained"
          size="large"
          type="submit"
          disabled={!isDirty}
          onClick={onSubmit}
        >
          Guardar porcentajes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
