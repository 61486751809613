import { DialogContent, Grid, Stack, useTheme } from '@mui/material';
import { ChangePassword } from '../../../../components/Illustrations/Illustrations';
import { ControlledPasswordInput } from '../../../../businessComponents/ControlledInputs';
import { useForm } from 'react-hook-form';
import { Typography } from '../../../../newComponents/Typography';
import { Button } from '../../../../newComponents/Button';
import { globalSnackbarVar } from '../../../../cache.reactiveVars';
import { successPasswordStyles } from '../ProfileDialog.styles';
import { useResetPassword } from '../Hooks/useResetPassword';
import { useScreenSize } from '../../../../Hooks';

const PasswordDefaultValues = {
  password: '',
  newPassword: '',
  confirmNewPassword: '',
};

export const ProfileChangePasswordContainer = () => {
  const theme = useTheme();
  const { isMobile } = useScreenSize();

  const { handleResetPassword } = useResetPassword();

  const passwordChangeForm = useForm({
    mode: 'all',
    defaultValues: PasswordDefaultValues,
  });

  const { control, watch, trigger } = passwordChangeForm;

  const [password, newPassword, confirmPassword] = watch([
    'password',
    'newPassword',
    'confirmNewPassword',
  ]);

  const handleSubmit = async (values) => {
    await handleResetPassword({
      password: values.password,
      newPassword: values.newPassword,
      onSuccess: () => {
        passwordChangeForm.reset(PasswordDefaultValues);
      },
    });
  };

  const handleSubmitError = () => {
    globalSnackbarVar({
      show: true,
      message: 'Asegúrate de que la información sea correcta',
      severity: 'error',
      duration: 5000,
    });
  };

  const isValid = passwordChangeForm?.formState?.isValid;
  const passwordMatch = newPassword && newPassword === confirmPassword;
  const disabled = !password || !newPassword || !confirmPassword;

  const handlePasswordChange = () => {
    setTimeout(() => {
      trigger(['newPassword', 'confirmNewPassword']);
    }, 250);
  };

  return (
    <DialogContent
      dividers
      sx={{
        py: 3,
        ...(!isMobile && { pb: 10 }),
      }}
    >
      <Grid container spacing={2} sx={{ ...(!isMobile && { height: '100%' }) }}>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Stack flex="1" justifyContent="center" alignItems="center">
            <ChangePassword height={isMobile ? '180px' : '350px'} />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={7}>
          <form
            style={{ height: '100%' }}
            onSubmit={passwordChangeForm.handleSubmit(
              handleSubmit,
              handleSubmitError,
            )}
          >
            <Stack
              width="100%"
              height="100%"
              flex="1"
              justifyContent="center"
              alignItems="left"
              spacing={2}
            >
              <Typography variant="h6" style={{ lineHeight: 1 }}>
                Cambio de contraseña
              </Typography>
              <ControlledPasswordInput
                control={control}
                textFieldProps={{
                  label: 'Contraseña actual',
                  required: true,
                  fullWidth: true,
                }}
                name="password"
              />
              <ControlledPasswordInput
                control={control}
                textFieldProps={{
                  label: 'Nueva contraseña',
                  required: true,
                  fullWidth: true,
                  ...(passwordMatch && { sx: successPasswordStyles(theme) }),
                }}
                name="newPassword"
                callBack={handlePasswordChange}
              />
              <ControlledPasswordInput
                control={control}
                textFieldProps={{
                  label: 'Confirmar nueva contraseña',
                  required: true,
                  fullWidth: true,
                  ...(passwordMatch && { sx: successPasswordStyles(theme) }),
                }}
                name="confirmNewPassword"
                rules={{
                  validate: (value) => {
                    return (
                      value === newPassword || 'Las contraseñas no coinciden'
                    );
                  },
                }}
                callBack={handlePasswordChange}
              />
              <Stack
                width="100%"
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!passwordMatch || disabled || !isValid}
                  type="submit"
                >
                  Confirmar
                </Button>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </Grid>
    </DialogContent>
  );
};
