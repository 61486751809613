import React, { Fragment, useState } from 'react';
import { Tabs } from '../../../../newComponents/Tabs';
import { Tab } from '../../../../newComponents/Tab';
import { Icon } from '../../../../components/Icons/Icons';
import {
  beneficiariesIsDirtyVar,
  openBeneficiariesWarningDialogVar,
  profileDialogTabVar,
  resetAllFiltersProfileDialog,
  resetOpenBeneficiariesWarningDialogVar,
  setOpenBeneficiariesWarningDialogVar,
} from '../ProfileDialog.vars';
import { PROFILE_TABS } from '../ProfileDialog.constants';
import { useReactiveVar } from '@apollo/client';
import { ConfirmDialog, Typography } from '../../../../newComponents';
import { Stack } from '@mui/material';

interface ProfileTabsProps {
  canShowNotificationTab: boolean;
  canShowBeneficiariesTab: boolean;
}

export const ProfileTabs = (props: ProfileTabsProps) => {
  const { canShowNotificationTab, canShowBeneficiariesTab } = props;
  const [temporalTab, setTemporalTab] = useState('');
  const [value, setValue] = useState(PROFILE_TABS.MY_DATA);
  const { open, action } = useReactiveVar(openBeneficiariesWarningDialogVar);
  const beneficiariesDirty = useReactiveVar(beneficiariesIsDirtyVar);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTemporalTab(newValue);
    if (value === PROFILE_TABS.BENEFICIARIES && beneficiariesDirty) {
      setOpenBeneficiariesWarningDialogVar({ open: true });
      return;
    }
    setValue(newValue);
    profileDialogTabVar(newValue);
  };

  const handleAccept = () => {
    if (action === 'CLOSE') {
      resetOpenBeneficiariesWarningDialogVar();
      resetAllFiltersProfileDialog();
      profileDialogTabVar(PROFILE_TABS.MY_DATA);
      return;
    }
    resetOpenBeneficiariesWarningDialogVar();
    setValue(temporalTab);
    profileDialogTabVar(temporalTab);
    beneficiariesIsDirtyVar(false);
  };

  const handleCloseWarningDialog = () => {
    resetOpenBeneficiariesWarningDialogVar();
  };

  return (
    <Fragment>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="profile tabs"
        color="primary"
        allowScrollButtonsMobile
        variant="scrollable"
      >
        <Tab
          label="Mis Datos"
          id="tab-1"
          icon={<Icon icon="user_line" />}
          iconPosition="start"
          value={PROFILE_TABS.MY_DATA}
        />
        <Tab
          label="Contraseña"
          id="tab-2"
          icon={<Icon icon="lock_password_line" />}
          iconPosition="start"
          value={PROFILE_TABS.CHANGE_PASSWORD}
        />
        {canShowBeneficiariesTab && (
          <Tab
            label="Mis beneficiarios"
            id="tab-3"
            icon={<Icon icon="user_heart_line" />}
            iconPosition="start"
            value={PROFILE_TABS.BENEFICIARIES}
          />
        )}
        <Tab
          label="Mis Firmas"
          id="tab-4"
          iconPosition="start"
          icon={<Icon icon="edit_line" />}
          value={PROFILE_TABS.MY_SIGNATURES}
        />
        <Tab
          label="Identificaciones"
          id="tab-5"
          icon={<Icon icon="passport_line" />}
          iconPosition="start"
          value={PROFILE_TABS.IDENTIFICATIONS}
        />
        {canShowNotificationTab && (
          <Tab
            label="Notificaciones"
            id="tab-5"
            icon={<Icon icon="passport_line" />}
            iconPosition="start"
            value={PROFILE_TABS.NOTIFICATIONS}
          />
        )}
      </Tabs>

      <ConfirmDialog
        open={open}
        onClose={handleCloseWarningDialog}
        onConfirm={handleAccept}
        onCancel={handleCloseWarningDialog}
        confirmLabel="Aceptar"
        cancelLabel="Cancelar"
        title="¿Descartar cambios?"
        confirmButtonToRight
        handleCloseIconButton
      >
        <Stack width="100%">
          <Typography variant="body1" color="text.secondary">
            Si sales de esta pantalla se perderán los cambios realizados.
          </Typography>
        </Stack>
      </ConfirmDialog>
    </Fragment>
  );
};
