import { useController } from 'react-hook-form';
import { Autocomplete } from '../../../newComponents/Autocomplete';
import { TextInput } from '../../../newComponents/TextInput';
import { ControlledRelationshipInputProps } from './ControlledRelationshipInput.types';
import { useScreenSize } from '../../../Hooks';

export const RELATIONSHIP_MAP = {
  FATHER: 'Padre',
  MOTHER: 'Madre',
  SIBLING: 'Hermano(a)',
  CHILD: 'Hijo(a)',
  SPOUSE: 'Esposo(a)',
  PARTNER: 'Pareja',
  FRIEND: 'Amigo(a)',
  OTHER: 'Otro',
};

const OPTIONS = Object.entries(RELATIONSHIP_MAP).map(([value, label]) => ({
  value,
  label,
}));

export const ControlledRelationshipInput = (
  props: ControlledRelationshipInputProps,
) => {
  const { isMobile } = useScreenSize();
  const { control, rules, textFieldProps = {} } = props;
  const name = textFieldProps.name || 'relationship';
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const hasError = Boolean(error);
  const helperText = error?.message || '';

  return (
    <Autocomplete
      id={textFieldProps.id || name}
      options={OPTIONS}
      getOptionLabel={(option) => option.label}
      value={OPTIONS.find((option) => option.value === value) || null}
      onChange={(_, newValue) => {
        if (!newValue) return onChange('');
        onChange(newValue.value);
      }}
      onBlur={onBlur}
      disabled={textFieldProps.disabled}
      ListboxProps={{
        ...(isMobile && {
          style: {
            maxHeight: 150,
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
          },
        }),
      }}
      renderInput={(params) => (
        <TextInput
          {...params}
          error={hasError}
          helperText={helperText}
          {...textFieldProps}
        />
      )}
    />
  );
};
