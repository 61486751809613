import { Stack } from '@mui/material';
import { setAddBeneficiaryDialogVar } from '../AddBeneficiaryDialog/AddBeneficiaryDialog.vars';
import { Button, Typography } from '../../../newComponents';
import { Icon } from '../../../components/Icons/Icons';
import { AddBeneficiaryDialog } from '../AddBeneficiaryDialog/AddBeneficiaryDialog';
import type { BeneficiariesConfig } from '../Beneficiaries.types';

interface BeneficiariesHeaderProps {
  beneficiariesLength: number;
  setBeneficiaries: React.Dispatch<React.SetStateAction<BeneficiariesConfig[]>>;
}

export const BeneficiariesHeaderContainer = (
  props: BeneficiariesHeaderProps,
) => {
  const { beneficiariesLength, setBeneficiaries } = props;
  const handleAddBeneficiary = async () => {
    setAddBeneficiaryDialogVar({ open: true });
  };

  return (
    <>
      <Stack
        width="100%"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          <Typography variant="h6">Beneficiarios</Typography>
          <Typography variant="subtitle2" color="text.secondary">
            Puedes elegir hasta 4 beneficiarios
          </Typography>
        </Stack>
        {beneficiariesLength < 4 && (
          <Button
            onClick={handleAddBeneficiary}
            variant="outlined"
            color="default"
            endIcon={<Icon icon="add_line" />}
            fullWidth={false}
          >
            Agregar
          </Button>
        )}
      </Stack>

      <AddBeneficiaryDialog setBeneficiaries={setBeneficiaries} />
    </>
  );
};
