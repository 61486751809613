import { makeVar } from '@apollo/client';
import type { UpdateBeneficiaryDialogVar } from './UpdateBeneficiaryDialog.types';

const updateBeneficiaryDialogDefault: UpdateBeneficiaryDialogVar = {
  open: false,
  onFinish: () => {},
  onClose: () => {},
};

export const updateBeneficiaryDialogVar = makeVar(
  updateBeneficiaryDialogDefault,
);

export const setUpdateBeneficiaryDialogVar = (
  input: Partial<UpdateBeneficiaryDialogVar>,
) => {
  updateBeneficiaryDialogVar({
    ...updateBeneficiaryDialogVar(),
    ...input,
  });
};

export const resetUpdateBeneficiaryDialog = () => {
  updateBeneficiaryDialogVar(updateBeneficiaryDialogDefault);
};
