import { gql } from '@apollo/client';

export const GET_INFO_REQUESTED_BY_COMPANY = gql`
  query getInfoRequestedByCompany(
    $input: GetInfoRequestedByCompanyFilterInput
  ) {
    getInfoRequestedByCompany(input: $input) {
      __typename
      ... on InfoRequestedByCompanyResult {
        requestedInfoToUsers {
          type
          optional
          completedBy
        }
      }
    }
  }
`;

export const UPDATE_INFO_REQUESTED_BY_COMPANY = gql`
  mutation updateInfoRequestedByCompany(
    $input: UpdateInfoRequestedByCompanyFilterInput
  ) {
    updateInfoRequestedByCompany(input: $input) {
      __typename
      ... on ErrorToUpdateInfoRequestedByCompany {
        message
      }
      ... on UpdateInfoRequestedByCompanyResult {
        requestedInfoToUsers {
          type
          optional
          completedBy
        }
      }
    }
  }
`;
