import {
  Stack,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Button } from '../../../newComponents';
import { Icon } from '../../../components/Icons/Icons';
import { useForm } from 'react-hook-form';
import { defaultBeneficiariesFields } from '../Beneficiaries.constants';
import { useReactiveVar } from '@apollo/client';
import {
  addBeneficiaryDialogVar,
  resetAddBeneficiaryDialog,
} from './AddBeneficiaryDialog.vars';
import { Dialog } from '../../../components/Dialogs/Dialog';
import { AddBeneficiaryDialogForm } from './AddBeneficiaryDialogForm';
import { distributePercentages } from '../Beneficiaries.helpers';
import { useEffect } from 'react';
import { globalSnackbarVar } from '../../../cache.reactiveVars';
import { BeneficiariesConfig } from '../Beneficiaries.types';
import type { AddBeneficiaryDialogProps } from './AddBeneficiaryDialog.types';

export const AddBeneficiaryDialog = (props: AddBeneficiaryDialogProps) => {
  const { open } = useReactiveVar(addBeneficiaryDialogVar);
  const { setBeneficiaries } = props;
  const form = useForm({
    mode: 'onChange',
    defaultValues: defaultBeneficiariesFields(),
  });

  const formIsValid = form.formState.isValid;
  const formIsDirty = form.formState.isDirty;

  const handleClose = () => {
    resetAddBeneficiaryDialog();
    form.reset();
  };

  const handleSubmit = () => {
    const values = form.getValues();

    setBeneficiaries((prev: BeneficiariesConfig[] = []) => {
      const newValues = [...prev, values];
      const newBeneficiaries = distributePercentages(newValues);
      return newBeneficiaries;
    });

    globalSnackbarVar({
      show: true,
      message: 'Beneficiario agregado con éxito',
      severity: 'success',
      duration: 5000,
    });
    handleClose();
  };

  useEffect(() => {
    if (open) {
      form.reset(defaultBeneficiariesFields());
    }
  }, [open, form]);

  return (
    <Dialog
      slideMode
      fullWidth
      open={open}
      maxWidth="xs"
      onClose={() => handleClose()}
      showCloseButton={false}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          py: 3,
        }}
        component="div"
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <Icon
            color="grey"
            icon="close_line"
            height="26px"
            onClick={() => handleClose()}
            style={{ cursor: 'pointer' }}
          />
          Agregar Beneficiario
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <AddBeneficiaryDialogForm form={form} />
      </DialogContent>
      <DialogActions sx={{ p: '24px' }}>
        <Button
          variant="contained"
          size="large"
          disabled={!formIsValid || !formIsDirty}
          onClick={form.handleSubmit(handleSubmit)}
        >
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
