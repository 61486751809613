import { MAP_INFO_TYPE } from './InfoRequestedByCompany.constants';
import type {
  InfoRequestedByCompany,
  OptionToChoose,
} from './InfoRequestedByCompany.types';

/**
 * @param {object} params
 * @param {import('../../../../../theme').CustomTheme} params.theme
 * @returns {object}
 */
export const gridInfoRequesteByCompanyTableStyle = ({ theme }) => {
  return {
    '& .MuiDataGrid-row': {
      borderBottom: `1px solid ${theme.newPalette.grey.transparent24}`,
    },
    '& .MuiDataGrid-columnHeaders': {
      paddingLeft: '10px',
      borderBottom: 'none',
    },
    '& .MuiDataGrid-footerContainer': {
      borderTop: 'none',
    },
  };
};

export const buildSelectedOption = (row: InfoRequestedByCompany) => {
  if (row.optional && row.completedBy === 'EMPLOYEE') {
    return 'IS_NOT_REQUIRED_EMPLOYEE' as OptionToChoose;
  }

  if (!row.optional && row.completedBy === 'EMPLOYEE') {
    return 'IS_REQUIRED_EMPLOYEE' as OptionToChoose;
  }

  if (row.completedBy === 'ADMIN') {
    return 'IS_NOT_REQUIRED_ADMIN' as OptionToChoose;
  }
};

export const modifyRequiredFields = (fields: InfoRequestedByCompany[]) => {
  if (!fields.length) return [];
  const modified = fields.map((info) => {
    return {
      ...info,
      modifiedType: MAP_INFO_TYPE[info.type]?.title || '',
    };
  });

  return modified as InfoRequestedByCompany[];
};
