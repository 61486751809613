import { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { DialogTitle, Stack } from '@mui/material';
import {
  beneficiariesIsDirtyVar,
  openProfileDialogVar,
  profileDialogTabVar,
  resetAllFiltersProfileDialog,
  setOpenBeneficiariesWarningDialogVar,
} from './ProfileDialog.vars';
import { Dialog } from '../../../newComponents/Dialog';
import { useScreenSize } from '../../../Hooks';
import { IconButton } from '../../../newComponents/IconButton';
import { Typography } from '../../../newComponents/Typography';
import { ProfileTabs } from './ProfileTabs/ProfileTabs';
import { ProfileUserDataContainer } from './ProfileUserData/ProfileUserDataContainer';
import { ProfileChangePasswordContainer } from './ProfileChangePassword/ProfileChangePasswordContainer';
import { ProfileMySignaturesContainer } from './ProfileMySignatures/ProfileMySignaturesContainer';
import { ProfileIdentificationsContainer } from './ProfileIdentifications/ProfileIdentificationsContainer';
import { ProfileUserNotificationsContainer } from './ProfileUserNotifications/ProfileUserNotificationsContainer/ProfileUserNotificationsContainer';
import { useGetCompanySignerNotificationsConfig } from './Hooks/useGetCompanySignerNotificationsConfig';
import { ProfileBeneficiariesContainer } from './ProfileBeneficiariesContainer/ProfileBeneficiariesContainer';
import { useGetCompleteBeneficiariesConfig } from '../../../businessComponents/Beneficiaries/Hooks/useGetCompleteBeneficiariesConfig';
import { PROFILE_TABS } from './ProfileDialog.constants';
import { currentRoleVar } from '../../../cache.reactiveVars';

const ProfileDialog = () => {
  const { isMobile } = useScreenSize();
  const { open } = useReactiveVar(openProfileDialogVar);
  const currentTab = useReactiveVar(profileDialogTabVar);
  const beneficiariesConfig = useGetCompleteBeneficiariesConfig();
  const currentRole = useReactiveVar(currentRoleVar);
  const { data, canShowNotificationTab, fetchNotificationsConfig } =
    useGetCompanySignerNotificationsConfig();
  const isDirty = useReactiveVar(beneficiariesIsDirtyVar);

  const handleClose = () => {
    if (isDirty && currentTab === PROFILE_TABS.BENEFICIARIES) {
      setOpenBeneficiariesWarningDialogVar({ open: true, action: 'CLOSE' });
      return;
    }

    resetAllFiltersProfileDialog();
    setTimeout(() => {
      profileDialogTabVar(PROFILE_TABS.MY_DATA);
    }, 500);
  };

  useEffect(() => {
    if (open) {
      fetchNotificationsConfig();
    }
  }, [fetchNotificationsConfig, open]);

  const showBeneficiaries =
    beneficiariesConfig.active && currentRole?.isEmployee;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      fullScreen={isMobile}
      sx={{
        '.MuiDialog-paper': {
          borderRadius: isMobile ? '0!important' : 2,
          // width: 1100,
          ...(!isMobile && { height: 700 }),
        },
      }}
    >
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <IconButton icon="arrow_left_s_line" onClick={handleClose} />
          <Typography variant="h5">Perfil</Typography>
        </Stack>
        <ProfileTabs
          canShowNotificationTab={canShowNotificationTab}
          canShowBeneficiariesTab={showBeneficiaries}
        />
      </DialogTitle>
      {currentTab === PROFILE_TABS.MY_DATA && <ProfileUserDataContainer />}
      {currentTab === PROFILE_TABS.CHANGE_PASSWORD && (
        <ProfileChangePasswordContainer />
      )}
      {currentTab === PROFILE_TABS.BENEFICIARIES && showBeneficiaries && (
        <ProfileBeneficiariesContainer />
      )}
      {currentTab === PROFILE_TABS.MY_SIGNATURES && (
        <ProfileMySignaturesContainer />
      )}
      {currentTab === PROFILE_TABS.IDENTIFICATIONS && (
        <ProfileIdentificationsContainer />
      )}
      {currentTab === PROFILE_TABS.NOTIFICATIONS && canShowNotificationTab && (
        <ProfileUserNotificationsContainer
          data={data}
          fetchNotificationsConfig={fetchNotificationsConfig}
        />
      )}
    </Dialog>
  );
};

export default ProfileDialog;
