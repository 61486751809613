import { Stack } from '@mui/material';
import { Controller } from 'react-hook-form';
import { Checkbox, FormControlLabel } from '../../../../../newComponents';
import { TooltipPermissionDetails } from './TooltipPermissionDetails';

export const RequestBeneficiaresPermission = ({ form }) => {
  return (
    <Stack spacing={2} direction="row" alignItems="center" m={2}>
      <TooltipPermissionDetails>
        <span>
          <Controller
            name="requestBeneficiariesToEmployees"
            control={form.control}
            render={({ field }) => {
              const { value, onChange } = field;
              const handleChange = () => {
                const currValue = !value;
                onChange(currValue);
                if (currValue) {
                  form.setValue('requestBeneficiaries', false);
                }
              };

              return (
                <FormControlLabel
                  control={<Checkbox checked={value} onChange={handleChange} />}
                  label="Marcar como obligatorio para los empleados"
                />
              );
            }}
          />
        </span>
      </TooltipPermissionDetails>
    </Stack>
  );
};
