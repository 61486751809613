import { makeVar } from '@apollo/client';
import type { CompletePersonalInformationDialogVar } from './CompletePersonalInformationDialog.types';
import { InfoRequestedByCompanyMap } from '../../../containers/MyCompany/Settings/Preferences/InfoRequestedByCompany/InfoRequestedByCompany.types';

const completePersonalInformationDialogDefault: CompletePersonalInformationDialogVar =
  {
    open: false,
    stepNumberLabel: '',
    showLogoutDialogAtClose: false,
    onFinish: () => {},
    onClose: () => {},
  };

export const completePersonalInformationDialogVar = makeVar(
  completePersonalInformationDialogDefault,
);

export const resetCompletePersonalInformationDialog = () => {
  completePersonalInformationDialogVar(
    completePersonalInformationDialogDefault,
  );
};

export const setCompletePersonalInformationDialogVar = (
  input: Partial<CompletePersonalInformationDialogVar>,
) => {
  completePersonalInformationDialogVar({
    ...completePersonalInformationDialogVar(),
    ...input,
  });
};

export const userInfoRequestedByCompanyVar = makeVar(
  null as InfoRequestedByCompanyMap | null,
);
