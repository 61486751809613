import { Tooltip, Typography } from '../../../../../newComponents';
import { Stack, useTheme } from '@mui/material';

const TooltipDetails = () => {
  const theme = useTheme();
  return (
    <Stack width="100%" textAlign="left">
      <Typography variant="subtitle2">
        Activalo si los empleados deben completar este dato{' '}
      </Typography>
      <Stack
        component="ul"
        px={2}
        py={2}
        sx={{ color: theme.newPalette.grey.grey500 }}
      >
        <li>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              Para empleados nuevos:
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Se les pedirá completar los datos cuando hagan su registro en sora
              por primera vez.
            </Typography>
          </Stack>
        </li>
        <li>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              Para usuarios existentes:
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Si no tienen este dato, se les pedirá que completen este dato en
              su próximo inicio de sesión.
            </Typography>
          </Stack>
        </li>
      </Stack>
    </Stack>
  );
};

export const TooltipPermissionDetails = ({ children }) => {
  const theme = useTheme();
  return (
    <Tooltip
      arrow
      placement="top"
      title={<TooltipDetails />}
      componentsProps={{
        tooltip: {
          sx: {
            background: 'white',
            boxShadow: theme.newPalette.shadow.dropdown,
            color: 'black',
            padding: 1.5,
            width: '350px',
            maxWidth: 'none',
          },
        },
        arrow: {
          sx: {
            color: theme.newPalette.common.white,
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};
