import { Box, Card, Divider, Stack, useTheme } from '@mui/material';
import { Typography } from '../../../../newComponents/Typography';
import { EmptySpaceChart } from '../../../../components/Illustrations/Illustrations';
import { Icon } from '../../../../components/Icons/Icons';
import { useCallback, useEffect, useState } from 'react';
import { useGetCryptoSignatures } from '../Hooks/useGetCryptoSignatures';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { Cert } from '../ProfileDialog.types';

export const AdvancedSignature = () => {
  const theme = useTheme();
  const [certs, setCerts] = useState<Cert[]>([]);
  const { handleGetSatCryptoSignature } = useGetCryptoSignatures();

  const fetchCerts = useCallback(() => {
    handleGetSatCryptoSignature({
      onSuccess: (response) => {
        setCerts(response);
      },
    });
  }, [handleGetSatCryptoSignature]);

  useEffect(() => {
    fetchCerts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack spacing={2} width="100%">
      <Card
        sx={{
          px: 2,
          py: 3,
          boxShadow: theme.newPalette.shadow.card,
        }}
      >
        <Stack width="100%" spacing={3}>
          <Typography variant="subtitle2" color="text.secondary">
            e.firma (SAT)
          </Typography>
          <Divider />
          <Box>
            {!certs?.length ? (
              <Stack
                width="100%"
                alignItems="center"
                justifyContent="center"
                spacing={4}
                height={280}
              >
                <EmptySpaceChart width="100px" height="100px" />
                <Typography variant="h5" color="text.secondary">
                  No hay firma registrada
                </Typography>
              </Stack>
            ) : (
              <>
                <Stack
                  width="100%"
                  spacing={3}
                  sx={{
                    ...(certs?.length > 3 && { height: 300 }),
                  }}
                  overflow="auto"
                >
                  <Stack spacing={1}>
                    <Typography variant="caption" color="primary">
                      Certificado(s) Públicos .cer
                    </Typography>
                  </Stack>
                  {/* LIST */}
                  {certs?.map((cert, i) => {
                    const validity = cert?.validityPeriod?.notAfter;
                    const dateTime = validity
                      ? format(new Date(validity), 'yyyy-MM-dd HH:mm:ss', {
                          locale: es,
                        })
                      : '';

                    return (
                      <Stack
                        key={i}
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        sx={{
                          background: theme.newPalette.background.neutral,
                          px: 2,
                          py: 1,
                          borderRadius: 2,
                        }}
                      >
                        <Icon icon="file_list_2_line" />
                        <Stack>
                          <Typography variant="caption">
                            {cert?.certName}
                          </Typography>
                          <Typography variant="caption">
                            <strong>RFC:</strong> {cert?.certRfc}
                          </Typography>
                          <Typography variant="caption">
                            <strong>Vigencia:</strong> {dateTime}
                          </Typography>
                        </Stack>
                      </Stack>
                    );
                  })}
                </Stack>
              </>
            )}
          </Box>
        </Stack>
      </Card>
    </Stack>
  );
};
