import { makeVar } from '@apollo/client';
import {
  FileViewerDialogVar,
  FileViewerDialogInSignableMode,
  FileViewerDialogInMarkAsViewedMode,
  FileViewerDialogInVisibleOnlyMode,
  FileViewerDialogInVideoAgreementMode,
  FileViewerDialogInBeneficiariesMode,
} from './FileViewerDialog.types';

const fileViewerDialogDefault: FileViewerDialogVar = {
  mode: 'VISIBLE_ONLY',
  open: false,
  isCarta: false,
  isAdmin: false,
  userId: '',
  file: null,
  fileExtension: 'pdf',
  showAlert: false,
  alertProps: {},
};

export const fileViewerDialogVar = makeVar<FileViewerDialogVar>(
  fileViewerDialogDefault,
);

export const resetFileViewerDialog = () => {
  fileViewerDialogVar(fileViewerDialogDefault);
};

export const openFileViewerDialogInSignableMode = (
  params: Pick<FileViewerDialogInSignableMode, 'file'> &
    Partial<
      Pick<
        FileViewerDialogInSignableMode,
        | 'isAdmin'
        | 'showAlert'
        | 'alertProps'
        | 'userId'
        | 'checkIfDocumentsModuleAvailable'
        | 'onSuccess'
        | 'onCloseConfirmDialog'
        | 'onNoFilesToSign'
        | 'user'
      >
    >,
) => {
  const {
    file,
    isAdmin = false,
    showAlert = false,
    alertProps = {},
    userId = '',
    user,
    checkIfDocumentsModuleAvailable = false,
    onSuccess = () => {},
    onCloseConfirmDialog = () => {},
    onNoFilesToSign = () => {},
  } = params;
  fileViewerDialogVar({
    ...fileViewerDialogDefault,
    mode: 'SIGNABLE',
    open: true,
    file,
    isAdmin,
    showAlert,
    alertProps,
    userId,
    user,
    checkIfDocumentsModuleAvailable,
    onSuccess,
    onCloseConfirmDialog,
    onNoFilesToSign,
  });
};

export const openFileViewerDialogInMarkAsViewedMode = (
  params: Pick<FileViewerDialogInMarkAsViewedMode, 'file'> &
    Partial<
      Pick<
        FileViewerDialogInMarkAsViewedMode,
        | 'isAdmin'
        | 'showAlert'
        | 'alertProps'
        | 'userId'
        | 'user'
        | 'onSuccess'
        | 'onCloseConfirmDialog'
      >
    >,
) => {
  const {
    file,
    isAdmin = false,
    showAlert = false,
    alertProps = {},
    userId = '',
    user,
    onSuccess = () => {},
    onCloseConfirmDialog = () => {},
  } = params;
  fileViewerDialogVar({
    ...fileViewerDialogDefault,
    mode: 'MARK_AS_VIEWED',
    open: true,
    file,
    isAdmin,
    showAlert,
    alertProps,
    userId,
    user,
    onSuccess,
    onCloseConfirmDialog,
  });
};

export const openFileViewerDialogInVisibleOnlyMode = (
  params: Pick<FileViewerDialogInVisibleOnlyMode, 'file'> &
    Partial<
      Pick<
        FileViewerDialogInVisibleOnlyMode,
        | 'fileExtension'
        | 'isAdmin'
        | 'userId'
        | 'user'
        | 'showAlert'
        | 'alertProps'
        | 'statusChip'
      >
    >,
) => {
  const {
    file,
    fileExtension = 'pdf',
    isAdmin = false,
    showAlert = false,
    alertProps = {},
    userId = '',
    user,
    statusChip,
  } = params;
  fileViewerDialogVar({
    ...fileViewerDialogDefault,
    mode: 'VISIBLE_ONLY',
    open: true,
    file,
    fileExtension,
    isAdmin,
    showAlert,
    alertProps,
    userId,
    user,
    statusChip,
  });
};

export const openFileViewerDialogForLetter = (
  params: Pick<FileViewerDialogInVisibleOnlyMode, 'userId'> &
    Partial<
      Pick<
        FileViewerDialogInVisibleOnlyMode,
        'showAlert' | 'alertProps' | 'user'
      >
    >,
) => {
  const { userId, showAlert = false, alertProps = {}, user } = params;
  fileViewerDialogVar({
    ...fileViewerDialogDefault,
    mode: 'VISIBLE_ONLY',
    open: true,
    isAdmin: true,
    isCarta: true,
    userId,
    showAlert,
    alertProps,
    user,
  });
};

export const openFileViewerDialogInVideoAgreementMode = (
  params: Pick<FileViewerDialogInVideoAgreementMode, 'file'> &
    Partial<
      Pick<
        FileViewerDialogInVideoAgreementMode,
        | 'textToRead'
        | 'showAlert'
        | 'alertProps'
        | 'user'
        | 'onSuccess'
        | 'onCloseConfirmDialog'
      >
    >,
) => {
  const {
    file,
    textToRead = '',
    showAlert = false,
    alertProps = {},
    user,
    onSuccess = () => {},
    onCloseConfirmDialog = () => {},
  } = params;
  fileViewerDialogVar({
    ...fileViewerDialogDefault,
    mode: 'VIDEO_AGREEMENT',
    open: true,
    file,
    textToRead,
    showAlert,
    alertProps,
    user,
    onSuccess,
    onCloseConfirmDialog,
  });
};

export const openFileViewerDialogInBeneficiariesMode = (
  params: Pick<FileViewerDialogInBeneficiariesMode, 'file'> &
    Partial<
      Pick<
        FileViewerDialogInBeneficiariesMode,
        | 'isAdmin'
        | 'showAlert'
        | 'alertProps'
        | 'userId'
        | 'onSuccess'
        | 'onCloseConfirmDialog'
        | 'beneficiariesNotifications'
      >
    >,
) => {
  const {
    file,
    isAdmin = false,
    showAlert = false,
    alertProps = {},
    userId = '',
    onSuccess = () => {},
    onCloseConfirmDialog = () => {},
    beneficiariesNotifications = null,
  } = params;
  fileViewerDialogVar({
    ...fileViewerDialogDefault,
    mode: 'BENEFICIARIES',
    open: true,
    file,
    isAdmin,
    showAlert,
    alertProps,
    userId,
    beneficiariesNotifications,
    onSuccess,
    onCloseConfirmDialog,
  });
};
