import { useApolloClient, useMutation, useReactiveVar } from '@apollo/client';
import {
  currentCompanyVar,
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../cache.reactiveVars';
import {
  GET_BENEFICIARIES,
  REQUEST_BENEFICIARIES_TO_EMPLOYEE,
} from '../Beneficiaries.gql';
import type { UseRequestBeneficiariesToEmployeeType } from '../Beneficiaries.types';

export function useRequestBeneficiariesToEmployee() {
  const company = useReactiveVar(currentCompanyVar);
  const [requestBeneficiariesToEmployee] = useMutation(
    REQUEST_BENEFICIARIES_TO_EMPLOYEE,
  );
  const client = useApolloClient();

  const handleRequestBeneficiariesToEmployee = async (
    data: UseRequestBeneficiariesToEmployeeType,
  ) => {
    const { userId, notificationType, onSuccess } = data;

    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Solicitando información...',
      });

      const response = await requestBeneficiariesToEmployee({
        variables: {
          input: {
            companyId: company._id,
            employeeId: userId,
            notificationType,
          },
        },
      });

      const data = response?.data?.requestBeneficiariesToEmployee;

      if (data?.__typename === 'ErrorToRequestBeneficiariesToEmployee') {
        globalSnackbarVar({
          show: true,
          message: data.message,
          severity: 'error',
        });
        return;
      }

      if (onSuccess) {
        onSuccess();
      }

      globalSnackbarVar({
        show: true,
        message: 'Solicitud enviada correctamente',
        severity: 'success',
      });

      await client.refetchQueries({ include: [GET_BENEFICIARIES] });
    } catch {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { handleRequestBeneficiariesToEmployee };
}
