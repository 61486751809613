// @ts-check
import React from 'react';
import { useController } from 'react-hook-form';
import { TextInput } from '../../../newComponents/TextInput';
import { namesRegex } from '../../../utils/validators';
import { applySanitizers } from '../../../utils/sanitizers';

/** @type {import('./ControlledLastNamePInput.types').Rules} */
const defaultRules = {
  required: 'Campo requerido',
  pattern: {
    value: namesRegex,
    message: 'Apellido paterno inválido',
  },
};

/** @param {import('./ControlledLastNamePInput.types').ControlledLastNamePInputProps} props */
export const ControlledLastNamePInput = ({
  control,
  rules = {},
  textFieldProps = {},
}) => {
  const name = textFieldProps.name || 'lastNameP';
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { ...defaultRules, ...rules },
  });

  const hasError = Boolean(error);
  const helperText = error?.message || '';

  return (
    <TextInput
      id={textFieldProps.id || name}
      value={value}
      onChange={(e) => {
        const inputValue = applySanitizers({
          text: e.target.value,
          sanitizers: ['capitalizeWords'],
        });
        onChange(inputValue);
      }}
      onBlur={onBlur}
      error={hasError}
      helperText={helperText}
      {...textFieldProps}
    />
  );
};
