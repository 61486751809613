import { useEffect } from 'react';
import { UserOnboardingDialog } from '../../businessComponents/Dialogs/UserOnboardingDialog';
import { IdentityVerificationDialog } from '../../businessComponents/Dialogs/IdentityVerificationDialog';
import { ElectronicSignatureCreationDialog } from '../../businessComponents/Dialogs/ElectronicSignatureCreationDialog';
import { SignOnboardingDocumentsDialog } from '../../businessComponents/Dialogs/SignOnboardingDocumentsDialog';
import { RequestedEmployeeDocumentsDialog } from '../../businessComponents/Dialogs/RequestedEmployeeDocumentsDialog';
import { CompletePersonalInformationDialog } from '../../businessComponents/Dialogs/CompletePersonalInformationDialog';
import { DocumentSignDialog } from '../../businessComponents/Dialogs/DocumentSignDialog';
import { ElectronicSignatureWorkflowDialog } from '../../businessComponents/Dialogs/EletronicSignatureWorkflowDialog';
import { RevokeElectronicSignatureDialog } from '../../businessComponents/Dialogs/RevokeElectronicSignatureDialog';
import { FileViewerDialog } from '../../businessComponents/Dialogs/FileViewerDialog';
import { WebcamDialog } from '../../businessComponents/Dialogs/WebcamDialog';
import { LogoutDialog } from '../../businessComponents/Dialogs/LogoutDialog';
import { FullScreenLottieAnimation } from '../../businessComponents/LottieAnimation';
import { useStartUserOnboarding } from '../../businessComponents/Dialogs/UserOnboardingDialog/UserOnboardingDialogHooks/useStartUserOnboarding';
import { VideoAgreementRecorderDialog } from '../../businessComponents/Dialogs/VideoAgreementRecorderDialog';
import { VideoAgreementPromptDialog } from '../Settings/Preferences/VideoAgreementTemplates/VideoAgreementPromptDialog';
import { LivenessDialog } from '../../businessComponents/Dialogs/LivenessDialog';
import { CompleteOnboardingBeneficiariesDialog } from '../../businessComponents/Dialogs/CompleteOnboardingBeneficiariesDialog/CompleteOnboardingBeneficiariesDialog';

export function GlobalComponents() {
  const { handleStartUserOnboarding } = useStartUserOnboarding();

  useEffect(() => {
    handleStartUserOnboarding();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <UserOnboardingDialog />
      <IdentityVerificationDialog />
      <ElectronicSignatureCreationDialog />
      <SignOnboardingDocumentsDialog />
      <RequestedEmployeeDocumentsDialog />
      <CompletePersonalInformationDialog />
      {/* <CompleteBeneficiariesDialog /> */}
      <CompleteOnboardingBeneficiariesDialog />
      <DocumentSignDialog />
      <ElectronicSignatureWorkflowDialog />
      <RevokeElectronicSignatureDialog />
      <FileViewerDialog />
      <WebcamDialog />
      <LogoutDialog />
      <FullScreenLottieAnimation />
      <VideoAgreementRecorderDialog />
      <VideoAgreementPromptDialog />
      <LivenessDialog />
    </>
  );
}
