import {
  Stack,
  DialogContent,
  DialogTitle,
  Box,
  DialogActions,
} from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { Dialog } from '../../../newComponents/Dialog';
import { Typography } from '../../../newComponents/Typography';
import { IconButton } from '../../../newComponents/IconButton';
import { Avatar } from '../../../newComponents/Avatar';
import { useScreenSize } from '../../../Hooks';

import { setLogoutDialogVar } from '../LogoutDialog/LogoutDialog.vars';
import {
  completeOnboardingBeneficiariesDialogVar,
  resetCompleteOnboardingBeneficiariesDialog,
} from './CompleteOnboardingBeneficiariesDialog.vars';
import { BeneficiariesHeaderContainer } from '../../Beneficiaries/BeneficiariesHeaderContainer/BeneficiariesHeaderContainer';
import { BeneficiariesContainerList } from '../../Beneficiaries/BeneficiariesContainerList/BeneficiariesContainerList';
import { useCreateBeneficiariesDocument } from '../../Beneficiaries/Hooks/useCreateBeneficiariesDocument';
import { openFileViewerDialogInSignableMode } from '../FileViewerDialog/FileViewerDialog.vars';
import { globalSnackbarVar, userVar } from '../../../cache.reactiveVars';
import { useGetBeneficiaries } from '../../Beneficiaries/Hooks/useGetBeneficiaries';
import { useEffect, useState } from 'react';
import { useDeleteBeneficiariesDocument } from '../../Beneficiaries/Hooks/useDeleteBeneficiariesDocument';
import type { BeneficiariesConfig } from '../../Beneficiaries/Beneficiaries.types';
import { CompleteOnboardingBeneficiariesActionButton } from './CompleteOnboardingBeneficiariesActionButton';
import { useUpdateEmployeeInfoRequired } from '../../Beneficiaries/Hooks/useUpdateEmployeeInfoRequired';

export const CompleteOnboardingBeneficiariesDialog = () => {
  const { isMobile } = useScreenSize();
  const user = useReactiveVar(userVar);
  const { open, onFinish, stepNumberLabel, showLogoutDialogAtClose, onClose } =
    useReactiveVar(completeOnboardingBeneficiariesDialogVar);
  const [beneficiaries, setBeneficiaries] = useState<BeneficiariesConfig[]>([]);

  const { data, requestedByAdminIsPending } = useGetBeneficiaries({
    userId: user?._id,
    open,
  });
  const { handleCreateBeneficiariesDocument } =
    useCreateBeneficiariesDocument();
  const { handleDeleteBeneficiariesDocument } =
    useDeleteBeneficiariesDocument();

  const { handleUpdateEmployeeInfoRequired } = useUpdateEmployeeInfoRequired();

  const handleClose = () => {
    if (showLogoutDialogAtClose) {
      return setLogoutDialogVar({ open: true });
    }
    resetCompleteOnboardingBeneficiariesDialog();
    onClose && onClose();
  };

  const handleSkip = () => {
    handleUpdateEmployeeInfoRequired({
      employeeInfo: ['BENEFICIARIES'],
      status: 'SKIPPED',
      onSuccess: () => {
        resetCompleteOnboardingBeneficiariesDialog();
        onFinish && onFinish();
      },
    });
  };

  const handleSubmit = () => {
    if (!user?._id) return;
    handleCreateBeneficiariesDocument({
      beneficiaries: beneficiaries,
      onSuccess: (document: any) => {
        openFileViewerDialogInSignableMode({
          file: {
            id: document?.documentId,
            title: 'Beneficiarios.pdf',
            type: 'DOCUMENT',
          },
          onSuccess: () => {
            resetCompleteOnboardingBeneficiariesDialog();
            onFinish && onFinish();
          },
          onCloseConfirmDialog: () => {
            handleDeleteBeneficiariesDocument({
              employeeDocumentId: document?.documentId,
            });
          },
          onNoFilesToSign: () => {
            globalSnackbarVar({
              show: true,
              message: 'No hay documentos para firmar',
              severity: 'info',
            });
          },
        });
      },
    });
  };

  useEffect(() => {
    setBeneficiaries(data?.length ? data : []);
  }, [data]);

  const percentageSum = beneficiaries.reduce(
    (acc, beneficiary) => acc + Number(beneficiary.percentage),
    0,
  );
  const isValidPercentage = percentageSum === 100;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      fullWidth
      PaperProps={{ sx: { borderRadius: isMobile ? '0px' : '16px' } }}
    >
      <DialogTitle
        component="div"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
        sx={{ padding: '24px' }}
      >
        <Stack>
          <Stack direction="row" gap={2} alignItems="center">
            {stepNumberLabel && (
              <Avatar color="primary" size="tiny">
                {stepNumberLabel}
              </Avatar>
            )}
            <Typography variant="h6">Completa tu expediente</Typography>
          </Stack>
          {!isMobile && (
            <Typography variant="body2">
              Completa la información de tus beneficiarios para continuar con tu
              expediente.
            </Typography>
          )}
        </Stack>
        <Box>
          <IconButton
            icon="close_line"
            onClick={handleClose}
            aria-label="Cerrar diálogo"
          />
        </Box>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{ minHeight: isMobile ? '50vh' : '600px', padding: '24px' }}
      >
        <BeneficiariesHeaderContainer
          beneficiariesLength={beneficiaries.length}
          setBeneficiaries={setBeneficiaries}
        />
        <BeneficiariesContainerList
          beneficiaries={beneficiaries}
          setBeneficiaries={setBeneficiaries}
        />
      </DialogContent>
      <DialogActions sx={{ padding: '24px' }}>
        <CompleteOnboardingBeneficiariesActionButton
          isValidPercentage={isValidPercentage}
          requestedByAdminIsPending={requestedByAdminIsPending}
          beneficiariesLength={beneficiaries.length}
          handleSkip={handleSkip}
          handleSubmit={handleSubmit}
        />
      </DialogActions>
    </Dialog>
  );
};
