import { useMutation, useReactiveVar } from '@apollo/client';
import {
  currentCompanyVar,
  globalBackdropVar,
  globalSnackbarVar,
  userVar,
} from '../../../cache.reactiveVars';
import { UPDATE_EMPLOYEE_INFO_REQUIRED } from '../../../containers/RHPod/Employees/Employees.gql';
import type { UseUpdateEmployeeInfoRequiredType } from '../Beneficiaries.types';

export function useUpdateEmployeeInfoRequired() {
  const company = useReactiveVar(currentCompanyVar);
  const user = useReactiveVar(userVar);
  const [updateEmployeeInfoRequired] = useMutation(
    UPDATE_EMPLOYEE_INFO_REQUIRED,
  );

  const handleUpdateEmployeeInfoRequired = async (
    data: UseUpdateEmployeeInfoRequiredType,
  ) => {
    const { employeeInfo, status, onSuccess } = data;

    if (!employeeInfo.length || !status) {
      globalSnackbarVar({
        show: true,
        message: 'Faltan datos para enviar la solicitud',
        severity: 'error',
      });
      return;
    }

    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Actualizando información...',
      });

      const response = await updateEmployeeInfoRequired({
        variables: {
          input: {
            companyId: company._id,
            userId: user?._id,
            employeeInfo,
            status,
          },
        },
      });

      const data = response?.data?.updateEmployeeInfoRequired;

      if (data?.__typename === 'ErrorToUpdateEmployeeInfoRequired') {
        globalSnackbarVar({
          show: true,
          message: data.message,
          severity: 'error',
        });
        return;
      }

      if (onSuccess) {
        onSuccess();
      }

      globalSnackbarVar({
        show: true,
        message: 'Actualización exitosa',
        severity: 'success',
      });
    } catch {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { handleUpdateEmployeeInfoRequired };
}
