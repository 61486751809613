import { Card, DialogContent, Grid, Stack, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import type {
  Identification,
  IdentificationsType,
} from '../ProfileDialog.types';
import { Typography } from '../../../../newComponents/Typography';
import { MenuItem } from '../../../../newComponents/MenuItem';
import { Icon } from '../../../../components/Icons/Icons';
import { useGetVerificationData } from '../Hooks/useGetVerificationData';
import { VerificationId } from './VerificationId';
import { useScreenSize } from '../../../../Hooks';

export const ProfileIdentificationsContainer = () => {
  const theme = useTheme();
  const { isMobile } = useScreenSize();
  const [idOption, setIdOption] = useState<IdentificationsType>('INE');
  const [ine, setIne] = useState<Identification | null>(null);
  const [passport, setPassport] = useState<Identification | null>(null);
  const [imageFound, setImageFound] = useState({
    ine: false,
    passport: false,
    other: false,
  });

  const { handleGetVerificationData } = useGetVerificationData();

  const handleIdOption = (option: IdentificationsType) => {
    setIdOption(option);
  };

  const getType = () => {
    if (idOption === 'INE') return 'INEIFE';
    if (idOption === 'PASSPORT') return 'PASSPORT';
    return 'OTHER';
  };

  useEffect(() => {
    if (imageFound?.ine && idOption === 'INE') return;
    if (imageFound?.passport && idOption === 'PASSPORT') return;
    if (imageFound?.other && idOption === 'OTHER') return;

    handleGetVerificationData({
      type: getType(),
      onSuccess: (response) => {
        const frontUrl = response?.frontIdentificationUrl;
        const backUrl = response?.backIdentificationUrl;

        if (idOption === 'INE') {
          if (frontUrl && backUrl) {
            setImageFound({ ...imageFound, ine: true });
          }
          setIne(response);
        }

        if (idOption === 'PASSPORT') {
          if (frontUrl) {
            setImageFound({ ...imageFound, passport: true });
          }
          setPassport(response);
        }

        if (idOption === 'OTHER') {
          if (frontUrl) {
            setImageFound({ ...imageFound, other: true });
          }
          setPassport(response);
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idOption]);

  return (
    <DialogContent
      dividers
      sx={{
        py: 3,
        ...(!isMobile && { pb: 10 }),
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={3}>
          <Card sx={{ py: 3, boxShadow: theme.newPalette.shadow.card }}>
            <Stack sx={{ px: 2 }}>
              <Typography variant="subtitle2" color="text.secondary">
                Documentos
              </Typography>
            </Stack>

            <Stack width="100%" mt={2} spacing={2}>
              <MenuItem
                selected={idOption === 'INE'}
                style={{ marginRight: 8, marginLeft: 8 }}
                onClick={() => handleIdOption('INE')}
              >
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Icon icon="file_list_2_line" />
                  <Typography>INE</Typography>
                </Stack>
              </MenuItem>
              <MenuItem
                selected={idOption === 'PASSPORT'}
                style={{ marginRight: 8, marginLeft: 8 }}
                onClick={() => handleIdOption('PASSPORT')}
              >
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Icon icon="file_list_2_line" />
                  <Typography>Pasaporte</Typography>
                </Stack>
              </MenuItem>
              <MenuItem
                selected={idOption === 'OTHER'}
                style={{ marginRight: 8, marginLeft: 8 }}
                onClick={() => handleIdOption('OTHER')}
              >
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Icon icon="file_list_2_line" />
                  <Typography>Otro</Typography>
                </Stack>
              </MenuItem>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={9}>
          {idOption === 'INE' && (
            <VerificationId
              idOption={idOption}
              frontUrl={ine?.frontIdentificationUrl}
              backUrl={ine?.backIdentificationUrl}
              imageFound={imageFound.ine}
            />
          )}
          {idOption === 'PASSPORT' && (
            <VerificationId
              idOption={idOption}
              frontUrl={passport?.frontIdentificationUrl}
              backUrl={passport?.backIdentificationUrl}
              imageFound={imageFound.passport}
            />
          )}
          {idOption === 'OTHER' && (
            <VerificationId
              idOption={idOption}
              frontUrl={passport?.frontIdentificationUrl}
              imageFound={imageFound.other}
            />
          )}
        </Grid>
      </Grid>
    </DialogContent>
  );
};
