import type {
  InfoRequestedActionOptionItem,
  MappingInfoRequested,
  RequestedFields,
} from './InfoRequestedByCompany.types';

export const INFO_REQUESTED_OPTIONS: InfoRequestedActionOptionItem[] = [
  {
    key: 'IS_NOT_REQUIRED_ADMIN',
    label: 'Administrador completa el dato',
    completedBy: 'ADMIN',
    optional: true,
  },
  {
    key: 'IS_REQUIRED_EMPLOYEE',
    label: 'Solicitar al empleado (obligatorio)',
    completedBy: 'EMPLOYEE',
    optional: false,
  },
  {
    key: 'IS_NOT_REQUIRED_EMPLOYEE',
    label: 'Solicitar al empleado (opcional)',
    completedBy: 'EMPLOYEE',
    optional: true,
  },
];

export const MAP_INFO_TYPE: Record<RequestedFields, MappingInfoRequested> = {
  BIRTHDATE: {
    title: 'Fecha de nacimiento',
    label: 'Datos personales',
    color: 'primary',
  },
  GENDER: {
    title: 'Género',
    label: 'Datos personales',
    color: 'primary',
  },
  MARITAL_STATUS: {
    title: 'Estado civil',
    label: 'Datos personales',
    color: 'primary',
  },
  ADDRESS: {
    title: 'Dirección',
    label: 'Datos de contacto',
    color: 'warning',
  },
  SOCIAL_SECURITY_NUMBER: {
    title: 'Número de seguro social',
    label: 'Datos laborales',
    color: 'success',
  },
};
