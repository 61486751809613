import { makeVar } from '@apollo/client';
import { PROFILE_TABS } from './ProfileDialog.constants';

const profileDialogVarDefault = { open: false };

export const openProfileDialogVar = makeVar(profileDialogVarDefault);

export const resetOpenProfileDialogVar = () => {
  openProfileDialogVar(profileDialogVarDefault);
};

export const setOpenProfileDialogVar = (
  input: Partial<typeof profileDialogVarDefault>,
) => {
  openProfileDialogVar({
    ...openProfileDialogVar(),
    ...input,
  });
};

const openVerifyContactMethodDialogVarDefault = { open: false };

export const openVerifyContactMethodDialogVar = makeVar(
  openVerifyContactMethodDialogVarDefault,
);

export const resetOpenVerifyContactMethodDialogVar = () => {
  openVerifyContactMethodDialogVar(openVerifyContactMethodDialogVarDefault);
};

const revokeSignatureDialogVarDefault = { open: false };

export const openRevokeSignatureDialogVar = makeVar(
  revokeSignatureDialogVarDefault,
);

export const resetOpenRevokeSignatureDialogVar = () => {
  openRevokeSignatureDialogVar(revokeSignatureDialogVarDefault);
};

export const profileDialogTabVar = makeVar(PROFILE_TABS.MY_DATA);

export const beneficiariesIsDirtyVar = makeVar(false);

const openBeneficiariesWarningDialogVarDefault = {
  open: false,
  action: '',
};

export const openBeneficiariesWarningDialogVar = makeVar(
  openBeneficiariesWarningDialogVarDefault,
);

export const resetOpenBeneficiariesWarningDialogVar = () => {
  openBeneficiariesWarningDialogVar(openBeneficiariesWarningDialogVarDefault);
};

export const setOpenBeneficiariesWarningDialogVar = (
  input: Partial<typeof openBeneficiariesWarningDialogVarDefault>,
) => {
  openBeneficiariesWarningDialogVar({
    ...openBeneficiariesWarningDialogVar(),
    ...input,
  });
};

export const resetAllFiltersProfileDialog = () => {
  resetOpenProfileDialogVar();
  resetOpenVerifyContactMethodDialogVar();
  resetOpenRevokeSignatureDialogVar();
  resetOpenBeneficiariesWarningDialogVar();
};
