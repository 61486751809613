import { Grid, Stack } from '@mui/material';
import type { BeneficiariesConfig } from '../Beneficiaries.types';
import { BeneficiaryItem } from './BeneficiaryItem';
import { Fragment, useEffect, useState } from 'react';
import { UpdateBeneficiaryDialog } from '../UpdateBeneficiaryDialog/UpdateBeneficiaryDialog';
import {
  setUpdateBeneficiaryDialogVar,
  updateBeneficiaryDialogVar,
} from '../UpdateBeneficiaryDialog/UpdateBeneficiaryDialog.vars';
import { useReactiveVar } from '@apollo/client';
import { Button } from '../../../newComponents';
import { Icon } from '../../../components/Icons/Icons';
import { BeneficiariesPercentageDialog } from '../BeneficiariesPercentageDialog/BeneficiariesPercentageDialog';
import { setBeneficiariesPercentageDialogVar } from '../BeneficiariesPercentageDialog/BeneficiariesPercentageDialog.vars';
import type { BeneficiariesContainerListProps } from './BeneficiariesContainerList.types';

export const BeneficiariesContainerList = (
  props: BeneficiariesContainerListProps,
) => {
  const { beneficiaries = [], setBeneficiaries, isAdminProfile } = props;
  const { open } = useReactiveVar(updateBeneficiaryDialogVar);
  const [beneficiaryToUpdate, setBeneficiaryToUpdate] =
    useState<BeneficiariesConfig | null>(null);

  const handleUpdateBeneficiary = (beneficiary: BeneficiariesConfig) => {
    setBeneficiaryToUpdate(beneficiary);
    setUpdateBeneficiaryDialogVar({ open: true });
  };

  const handlePercentages = () => {
    setBeneficiariesPercentageDialogVar({ open: true });
  };

  useEffect(() => {
    if (!open) {
      setBeneficiaryToUpdate(null);
    }
  }, [open]);

  const isSettingsPercentage = beneficiaries.length > 1 && !isAdminProfile;

  return (
    <Fragment>
      <Stack width="100%" direction="row" justifyContent="flex-end" my={2}>
        {isSettingsPercentage && (
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<Icon icon="percent_line" />}
            onClick={handlePercentages}
          >
            Ajustar porcentaje
          </Button>
        )}
      </Stack>

      <Grid container spacing={2}>
        {beneficiaries.map((beneficiary, index) => (
          <BeneficiaryItem
            beneficiary={beneficiary}
            key={index}
            onClick={handleUpdateBeneficiary}
            isAdminProfile={isAdminProfile}
          />
        ))}
      </Grid>
      <UpdateBeneficiaryDialog
        beneficiary={beneficiaryToUpdate}
        beneficiaries={beneficiaries}
        setBeneficiaries={setBeneficiaries}
      />
      <BeneficiariesPercentageDialog
        beneficiaries={beneficiaries}
        setBeneficiaries={setBeneficiaries}
      />
    </Fragment>
  );
};
