import { useEffect, useMemo } from 'react';
import {
  currentCompanyVar,
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../cache.reactiveVars';
import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_BENEFICIARIES } from '../Beneficiaries.gql';
import { mapRegisteredBeneficiaries } from '../Beneficiaries.helpers';
import type { UseGetBeneficiariesType } from '../Beneficiaries.types';

export const useGetBeneficiaries = (props: UseGetBeneficiariesType) => {
  const { excludeLoading, userId, open } = props;
  const company = useReactiveVar(currentCompanyVar);

  const openIsBoolean = typeof open === 'boolean';

  let skip = !company?._id || !userId;

  if (openIsBoolean && !open) {
    skip = true;
  }

  const { loading, data, refetch } = useQuery(GET_BENEFICIARIES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        companyId: company?._id,
        userId: userId,
      },
    },
    onError: () => {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al obtener beneficiarios',
        severity: 'error',
      });
    },
    skip: skip,
  });

  useEffect(() => {
    if (excludeLoading) return;
    if (loading) {
      globalBackdropVar({ open: loading, text: 'Obteniendo beneficiarios...' });
    } else {
      globalBackdropVar({ open: false });
    }
  }, [loading, excludeLoading]);

  const beneficiaries = useMemo(() => {
    const myBeneficiaries =
      data?.getBeneficiaries?.beneficiariesConfig?.beneficiaries ?? [];
    return mapRegisteredBeneficiaries(myBeneficiaries);
  }, [data]);

  const response = data?.getBeneficiaries;

  return {
    loading,
    data: beneficiaries,
    lastUpdated: response?.beneficiariesConfig?.timestamps,
    requestedByAdminIsPending: response?.isPending,
    lastDocumentId: response?.documentId,
    beneficiariesNotifications: response?.beneficiariesNotifications,
    refetch,
    handleGetBeneficiaries: () => null,
  };
};
