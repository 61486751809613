import { DialogContent, Grid } from '@mui/material';
import { SignatureOptions } from './SignatureOptions';
import { SimpleSignature } from './SimpleSignature';
import { useState } from 'react';
import type { SignatureOptionsType } from '../ProfileDialog.types';
import { AdvancedSignature } from './AdvancedSignature';
import { useScreenSize } from '../../../../Hooks';

export const ProfileMySignaturesContainer = () => {
  const { isMobile } = useScreenSize();
  const [signatureOption, setSignatureOption] =
    useState<SignatureOptionsType>('SIMPLE');

  const handleSignatureOption = (option: SignatureOptionsType) => {
    setSignatureOption(option);
  };

  return (
    <DialogContent
      dividers
      sx={{
        py: 3,
        ...(!isMobile && { pb: 10 }),
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <SignatureOptions
            signatureOption={signatureOption}
            handleSignatureOption={handleSignatureOption}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          {signatureOption === 'SIMPLE' && <SimpleSignature />}
          {signatureOption === 'ADVANCED' && <AdvancedSignature />}
        </Grid>
      </Grid>
    </DialogContent>
  );
};
