import { Stack } from '@mui/material';
import { Alert, Button, Typography } from '../../../../../../newComponents';
import type { EmployeeBeneficiariesHeaderProps } from './EmployeeBeneficiaries.types';
import { globalSnackbarVar } from '../../../../../../cache.reactiveVars';
import { openFileViewerDialogInBeneficiariesMode } from '../../../../../../businessComponents/Dialogs/FileViewerDialog/FileViewerDialog.vars';
import { useRequestBeneficiariesToEmployee } from '../../../../../../businessComponents/Beneficiaries/Hooks/useRequestBeneficiariesToEmployee';
import { hasHoursPassedWithRemainingTime } from '../../../../../../utils/time.utils';
import { usePermissionChecker } from '../../../../../../Hooks';
import {
  PermissionAction,
  PermissionCode,
} from '../../../../../../Hooks/usePermissionChecker';

export const EmployeeBeneficiariesHeader = (
  props: EmployeeBeneficiariesHeaderProps,
) => {
  const {
    beneficiariesLength,
    employee,
    requestedByAdminIsPending,
    documentId,
    beneficiariesNotifications,
  } = props;

  const userHasPermissionToViewDocs = usePermissionChecker({
    permission: PermissionCode.docs,
    action: PermissionAction.READ,
  });

  const { handleRequestBeneficiariesToEmployee } =
    useRequestBeneficiariesToEmployee();

  const requestBeneficiaries = () => {
    if (!employee.employeeId) {
      return globalSnackbarVar({
        show: true,
        message: 'No se ha encontrado el ID del empleado',
        severity: 'error',
      });
    }
    handleRequestBeneficiariesToEmployee({
      userId: employee.employeeId,
      notificationType: 'SINGLE',
    });
  };

  const handleOpenBeneficiariesDocument = () => {
    if (!documentId) {
      return globalSnackbarVar({
        show: true,
        message: 'Es necesario el ID del documento',
        severity: 'error',
      });
    }
    openFileViewerDialogInBeneficiariesMode({
      file: {
        id: documentId,
        title: 'Beneficiarios.pdf',
        type: 'DOCUMENT',
      },
      userId: employee.employeeId,
      beneficiariesNotifications,
      onSuccess: () => {},
    });
  };

  const hasHoursPassedResult = hasHoursPassedWithRemainingTime({
    givenDateString:
      beneficiariesNotifications?.receivedBeneficiaryNotifications,
    hours: 24,
  });

  const canSendNotif = hasHoursPassedResult?.hasPassed;

  return (
    <>
      <Stack width="100%">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Typography variant="h6">Beneficiarios</Typography>
          {beneficiariesLength === 0 && (
            <Button
              onClick={requestBeneficiaries}
              variant="outlined"
              color="default"
              fullWidth={false}
              tooltipArrow
              tooltipText={
                !canSendNotif
                  ? `Debes esperar 24 horas para enviar una nueva notificación, siguiente fecha disponible ${hasHoursPassedResult?.targetDateTime}`
                  : !requestedByAdminIsPending
                    ? 'Envía una notificación al empleado para que actualice sus beneficiarios'
                    : ''
              }
              tooltipPlacement="top"
              disabled={requestedByAdminIsPending || !canSendNotif}
            >
              Solicitar beneficiarios
            </Button>
          )}
          {beneficiariesLength > 0 && (
            <Button
              onClick={handleOpenBeneficiariesDocument}
              variant="outlined"
              color="default"
              fullWidth={false}
              disabled={!userHasPermissionToViewDocs}
              tooltipArrow
              tooltipText={
                !userHasPermissionToViewDocs
                  ? 'No tienes permisos para esta acción'
                  : ''
              }
              tooltipPlacement="top"
            >
              Ver información
            </Button>
          )}
        </Stack>
        {requestedByAdminIsPending && (
          <Alert severity="info" sx={{ mt: 2 }}>
            El empleado aún no ha firmado su comprobante de asignación de
            beneficiarios.
          </Alert>
        )}
      </Stack>
    </>
  );
};
