import {
  InfoRequestedByCompany,
  InfoRequestedByCompanyMap,
  RequestedFields,
} from '../../../containers/MyCompany/Settings/Preferences/InfoRequestedByCompany/InfoRequestedByCompany.types';

export const mapUserInfoRequestedByCompany = (
  fields: InfoRequestedByCompany[],
) => {
  if (!fields.length) return null;
  const objectFields = fields.reduce((acc, item) => {
    acc[item.type] = item;
    return acc;
  }, {} as InfoRequestedByCompanyMap);

  return objectFields;
};

export const isOnboardingFieldRequired = (
  fields: InfoRequestedByCompanyMap,
  type: RequestedFields,
) => {
  if (!fields) return true;
  return Boolean(fields[type]?.optional);
};
