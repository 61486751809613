import { Grid } from '@mui/material';
import {
  ControlledLastNameMInput,
  ControlledLastNamePInput,
  ControlledNamesInput,
  ControlledRelationshipInput,
} from '../../ControlledInputs';
import { ControlledPhoneInput } from '../../../components/ControlledInputs';
import { AddBeneficiaryDialogFormProps } from './AddBeneficiaryDialog.types';

export const AddBeneficiaryDialogForm = (
  props: AddBeneficiaryDialogFormProps,
) => {
  const { form } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ControlledNamesInput
          control={form.control}
          textFieldProps={{
            label: 'Nombre(s)',
            required: true,
            fullWidth: true,
            size: 'medium',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledLastNamePInput
          control={form.control}
          textFieldProps={{
            label: 'Apellido paterno',
            required: true,
            fullWidth: true,
            size: 'medium',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledLastNameMInput
          control={form.control}
          textFieldProps={{
            label: 'Apellido materno',
            fullWidth: true,
            size: 'medium',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledPhoneInput
          control={form.control}
          mergeWithDefaultRules
          muiProps={{ label: 'Celular' }}
          rules={{ required: false }}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledRelationshipInput
          control={form.control}
          textFieldProps={{
            label: 'Parentesco',
            required: true,
            fullWidth: true,
            size: 'medium',
          }}
          rules={{
            required: {
              value: true,
              message: 'Campo requerido',
            },
          }}
        />
      </Grid>
    </Grid>
  );
};
